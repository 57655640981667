import React, { useState, useEffect } from 'react';
import axios from 'axios';
// import './mystyles.css';
import * as XLSX from 'xlsx';


const DetailAbsensi = ({ content, url }) => {
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);
  const [selectedScope, setSelectedScope] = useState('');
  const [users, setUsers] = useState([]);
  const [attendanceData, setAttendanceData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [rombel, setRombel] = useState([]);
  const [selectedRombel, setSelectedRombel] = useState('')





  const handleMonthChange = (event) => {
    setSelectedMonth(parseInt(event.target.value, 10));
  };

  const getDaysInMonth = (month, year) => {
    return new Date(year, month, 0).getDate();
  };

  const renderDatesHeader = () => {
    const year = new Date().getFullYear();
    const daysInMonth = getDaysInMonth(selectedMonth, year);
    const dates = [];
    for (let day = 1; day <= daysInMonth; day++) {
      const date = new Date(year, selectedMonth - 1, day);
      const isSunday = date.getDay() === 0; // 0 represents Sunday
      dates.push(
        <th key={day} style={{ textAlign: 'center', backgroundColor: isSunday ? 'red' : 'transparent', width: '20px' }}>
          {day}
        </th>
      );
    }
    return dates;
  };

  const fetchDataUsers = async () => {
    setIsLoading(true);
    const token = localStorage.getItem("token");
    if (selectedScope === '2') {
      // Siswaa
      try {
        const response = await axios.post(url + "get-isi-rombel", {
          rombelId: selectedRombel
        }, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setUsers(response.data.data);
      } catch (error) {
        console.log(error);
      }
    } else {
      // Guru
      try {
        const response = await axios.get(url + "guru", {
          headers: { Authorization: `Bearer ${token}` },
        });
        setUsers(response.data.data);
      } catch (error) {
        console.log(error);
      }
    }
    setIsLoading(false);
  };

  const fetchDataForAllUsers = async (month, year) => {
    setIsLoading(true);
    const token = localStorage.getItem("token");
    if (selectedScope === '2') {
      // Siswa
      try {
        const response = await axios.post(`${url}absen-detail/batch`, {
          month,
          year,
          selectedRombel,
        }, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setAttendanceData(response.data);
      } catch (error) {
        console.log(error);
      }
    } else {
      // Guru
      try {
        const response = await axios.post(`${url}absen-detail-guru/batch`, {
          month,
          year,
        }, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setAttendanceData(response.data);
      } catch (error) {
        console.log(error);
      }

    }
    setIsLoading(false);
  };


  const fetchRombelAll = async () => {
    setIsLoading(true)
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(url + "rombel", {
        headers: { Authorization: `Bearer ${token}` },
      });
      setRombel(response.data.data);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false)
  };



  useEffect(() => {
    fetchRombelAll();
  }, [selectedScope]);

  useEffect(() => {
    fetchDataUsers();
  }, [selectedScope === '2' ? selectedRombel : selectedScope]);





  useEffect(() => {
    if (users.length > 0) {
      fetchDataForAllUsers(selectedMonth, new Date().getFullYear());
    }
  }, [users, selectedMonth]);


  const exportToExcel = () => {
    const year = new Date().getFullYear();
    const daysInMonth = getDaysInMonth(selectedMonth, year);
    const monthNames = ['Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember'];

    // Create a new workbook and worksheet
    const workbook = XLSX.utils.book_new();
    const worksheetData = [];

    // Add month name as a header row
    worksheetData.push([`Bulan: ${monthNames[selectedMonth - 1]} ${year}`]);

    // Add header row
    const headerRow = ['#', 'Nama', 'Ket'];
    for (let day = 1; day <= daysInMonth; day++) {
      headerRow.push(day.toString());
    }
    worksheetData.push(headerRow);

    // Add data rows
    attendanceData.forEach((userData, userIndex) => {
      const masukRow = [userIndex + 1, userData.user, 'Masuk'];
      const pulangRow = ['', '', 'Pulang'];

      Object.values(userData.masuk).forEach(msk => masukRow.push(msk));
      Object.values(userData.pulang).forEach(plg => pulangRow.push(plg));

      worksheetData.push(masukRow);
      worksheetData.push(pulangRow);
    });

    // Create worksheet
    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);

    // Apply styles for Sundays
    for (let day = 1; day <= daysInMonth; day++) {
      const date = new Date(year, selectedMonth - 1, day);
      if (date.getDay() === 0) { // 0 represents Sunday
        for (let row = 2; row <= worksheetData.length; row++) { // Start from row 2 to skip month header
          const cellAddress = XLSX.utils.encode_cell({ c: day + 2, r: row }); // Adjust column index
          if (!worksheet[cellAddress]) worksheet[cellAddress] = { t: 's', v: '' };
          worksheet[cellAddress].s = {
            fill: {
              patternType: "solid",
              fgColor: { rgb: "FF0000" } // Red color
            }
          };
        }
      }
    }

    // Append worksheet to workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Attendance');

    // Export workbook
    XLSX.writeFile(workbook, `Attendance_${selectedMonth}_${year}.xlsx`);
  };




  return (
    <div>
      <div className="container-fluid">
        <div className="page-title">
          <div className="row">
            <div className="col-sm-6 col-12">
              <h2>{content}</h2>
            </div>
            <div className="col-sm-6 col-12">
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><a href="index.html"><i className="fas fa-home"></i></a></li>
                <li className="breadcrumb-item">Dashboard /</li>
                <li className="breadcrumb-item active">{content}</li>
              </ol>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid ">
        <div className="row">
          <div className="col-sm-12">
            <div className="card overflow-hidden">
              <div className="card-header">
                <div className='row'>
                  <div className="col-md-4 mb-3">
                    <label htmlFor="monthSelect">Pilih Bulan: </label>
                    <select id="monthSelect" className='form-control' value={selectedMonth} onChange={handleMonthChange}>
                      {['Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember'].map((month, index) => (
                        <option key={index + 1} value={index + 1}>
                          {month}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="col-md-4 mb-3">
                    <label htmlFor="scopeData">Scope Data </label>
                    <select id="scopeData" className='form-control' value={selectedScope} onChange={(e) => setSelectedScope(e.target.value)}>
                      <option value=''>Pilih Scope</option>
                      <option value='1'>Guru / Karyawan</option>
                      <option value='2'>Siswa</option>
                    </select>
                  </div>

                  {selectedScope === '2' && (
                    <div className="col-md-4 mb-3">
                      <label htmlFor="selectRombel">Pilih Rombel </label>
                      <select id="selectRombel" className='form-control' value={selectedRombel} onChange={(e) => setSelectedRombel(e.target.value)}>
                        <option value=''>Pilih Rombel</option>
                        {rombel.map((item, index) => (
                          <option key={index} value={item.id}>
                            {item.nama_rombel}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}


                  <button onClick={exportToExcel} className="btn btn-primary">Export to Excel</button>

                </div>
              </div>
              <div className="card-body">
                <div className="table-responsive">

                  <table className="table">
                    <thead>
                      <tr className="border-bottom-secondary border-top-0">
                        <th scope="col" style={{ textAlign: 'center' }}>#</th>
                        <th scope="col" style={{ textAlign: 'center', width: '130px' }}>Nama</th>
                        <th scope="col" style={{ textAlign: 'center' }}>Ket</th>

                        <th scope="col" colSpan={getDaysInMonth(selectedMonth, new Date().getFullYear())} style={{ textAlign: 'center' }}>
                          Bulan {['Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember'][selectedMonth - 1]}
                        </th>
                      </tr>
                      <tr>
                        <th></th>
                        <th></th>
                        <th></th>
                        {renderDatesHeader()}
                      </tr>
                    </thead>
                    <tbody>
                      {isLoading ? (
                        <tr>
                          <td colSpan={getDaysInMonth(selectedMonth, new Date().getFullYear()) + 2} style={{ textAlign: 'center' }}>
                            Loading...
                          </td>
                        </tr>
                      ) : users.length === 0 ? (
                        <tr>
                          <td colSpan={getDaysInMonth(selectedMonth, new Date().getFullYear()) + 2} style={{ textAlign: 'center' }}>
                            No data available
                          </td>
                        </tr>
                      ) : (
                        attendanceData.map((userData, userIndex) => (
                          <>
                            <tr key={userIndex}>
                              <td style={{ textAlign: 'center' }} rowSpan="2">{userIndex + 1}</td>
                              <td style={{ textAlign: 'center' }} rowSpan="2">{userData.user}</td>
                              <td style={{ textAlign: 'center' }} >Masuk</td>


                              {Object.values(userData.masuk).map((msk, index) => (


                                <td key={`masuk-${index}`} style={{ textAlign: 'center' }}>
                                  <span
                                    className={`badge ${msk !== '❌' ? 'badge-success' : 'badge-danger'} badge-custom`}
                                    style={{ width: '70px' }} >
                                    {msk}
                                  </span>
                                </td>

                              ))}
                            </tr>
                            <tr>
                              <td style={{ textAlign: 'center' }} >Pulang</td>
                              {Object.values(userData.pulang).map((plg, index) => (

                                <td key={`pulang-${index}`} style={{ textAlign: 'center' }}>
                                  <span
                                    className={`badge ${plg !== '❌' ? 'badge-success' : 'badge-danger'} badge-custom`}
                                    style={{ width: '70px' }} // Adjust the width value as needed
                                  >
                                    {plg}
                                  </span>
                                </td>

                              ))}
                            </tr>
                          </>
                        ))
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
}

export default DetailAbsensi;