import React, { useState, useEffect } from 'react'
import axios from 'axios'


const SetAplikasi = ({ url, content }) => {

  const [nmSek, setNmSek] = useState('')
  const [alamat, setAlamat] = useState('')
  const [aplikasi, setAplikasi] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState([])


  const fetchData = async () => {
    setIsLoading(true)
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(url + "aplikasi");

      setData(response.data.data)
      console.log(response.data.length)

      if (response.data && response.data.data) {
        setNmSek(response.data.data.nama_sekolah || '');
        setAlamat(response.data.data.alamat || '');
        setAplikasi(response.data.data.nama_aplikasi || '');
      }


    } catch (error) {
      console.log(error);
    }
    setIsLoading(false)
  };


  useEffect(() => {
    fetchData();

  }, []);


  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevents the default form submission behavior
    // You can add your form submission logic here
    console.log('Nama Sekolah:', nmSek);
    console.log('Alamat Sekolah:', alamat);
    console.log('Nama Aplikasi:', aplikasi);


    setIsLoading(true)
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(url + "upd-aplikasi", {
        nama_sekolah: nmSek,
        alamat: alamat,
        nama_aplikasi: aplikasi
      }, {
        headers: { Authorization: `Bearer ${token}` },
      });
      fetchData();
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false)

  };


  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      console.log('Selected file:', file.name);
      // Example: Upload the file to a server
      const formData = new FormData();
      formData.append('logo', file);

      axios.post(url + "upload-logo-sek", formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      })
        .then(response => {
          fetchData()
          console.log('File uploaded successfully:', response.data);
          // Handle successful upload response
        })
        .catch(error => {
          console.error('Error uploading file:', error);
          // Handle upload error
        });
    }
  };

  const handleFileChangeApl = (e) => {
    const file = e.target.files[0];
    if (file) {
      console.log('Selected file:', file.name);
      // Example: Upload the file to a server
      const formData = new FormData();
      formData.append('logo', file);

      axios.post(url + "upload-logo-apk", formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      })
        .then(response => {
          fetchData()
          console.log('File uploaded successfully:', response.data);
          // Handle successful upload response
        })
        .catch(error => {
          console.error('Error uploading file:', error);
          // Handle upload error
        });
    }
  };




  return (
    <div>
      <div className="container-fluid">
        <div className="page-title">
          <div className="row">
            <div className="col-sm-6 col-12">
              <h2>{content}</h2>
            </div>
            <div className="col-sm-6 col-12">
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><a href="index.html"><i className="fas fa-home"></i></a></li>
                <li className="breadcrumb-item">Dashboard /</li>

                <li className="breadcrumb-item active">{content}</li>
              </ol>
            </div>
          </div>
        </div>
      </div>


      <div className="container-fluid table-space basic_table">
        <div className="row">
          <div className="col-md-8">
            <div className="card">
              <div className="card-body">
                <form onSubmit={handleSubmit}>
                  <div className="col-md-12">
                    <label className="form-label" htmlFor="nmsek">Nama Sekolah</label>
                    <input
                      className="form-control"
                      id="nmsek"
                      type="text"
                      placeholder="Masukkan Nama Sekolah"
                      value={nmSek}
                      onChange={(e) => setNmSek(e.target.value)}
                      required />
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="form-label" htmlFor="almt">Alamat Sekolah</label>
                    <input
                      className="form-control"
                      id="almt"
                      type="text"
                      placeholder="Masukkan Alamat Sekolah"
                      value={alamat}
                      onChange={(e) => setAlamat(e.target.value)}
                      required />
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="form-label" htmlFor="apl">Nama Aplikasi</label>
                    <input
                      className="form-control"
                      id="apl"
                      type="text"
                      placeholder="Masukkan Nama Aplikasi"
                      value={aplikasi}
                      onChange={(e) => setAplikasi(e.target.value)}
                      required />
                  </div>
                  <button type="submit" className="btn btn-primary mt-3">Simpan</button>
                </form>
              </div>
            </div>
          </div>


          <div className="col-md-4">
            <div className="card">
              <div className="card-body">
                <form>
                  <div className="col-md-12">
                    <label className="form-label" htmlFor="logosek">Logo Sekolah</label>
                    <input
                      className="form-control"
                      id="logosek"
                      type="file"
                      placeholder="Masukkan Logo Sekolah"
                      onChange={(e) => handleFileChange(e)}
                      required />
                  </div>
                </form>
                <img src={data.logo} width={80} className='mt-3' />
              </div>
            </div>
            <div className="card">
              <div className="card-body">
                <form>
                  <div className="col-md-12">
                    <label className="form-label" htmlFor="logoapl">Logo Aplikasi</label>
                    <p>Ukuran 122px x 40px</p>
                    <input
                      className="form-control"
                      id="logoapl"
                      type="file"
                      placeholder="Masukkan Logo Aplikasi"
                      onChange={(e) => handleFileChangeApl(e)}
                      required />
                  </div>
                </form>
                <img src={data.logo_aplikasi} width={150} className='mt-3' />
              </div>
            </div>
          </div>

        </div>

      </div>


    </div>
  )
}

export default SetAplikasi
