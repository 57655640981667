import React, { useState, useEffect } from 'react'
import axios from 'axios'
import * as XLSX from 'xlsx';



const UserMan = ({ url, content, scope }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [users, setUsers] = useState([])


  const fetchUsers = async () => {
    setIsLoading(true)
    const token = localStorage.getItem("token");

    try {
      const response = await axios.post(url + "userman", {
        role: scope
      }, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setUsers(response.data.data);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false)
  };



  useEffect(() => {
    fetchUsers();
  }, []);

  const exportToExcel = () => {
    const filteredUsers = users.map(user => {
      if (scope === '3') {
        return {
          'PEGID / NIK': user.nik,
          'NAMA': user.name,
          'Username': user.email,
          'Password': user.original_password
        };
      } else if (scope === '2') {
        return {
          'Level': user.level,
          'Rombel': user.rombel,
          'Nama Siswa': user.name,
          'Username': user.email,
          'Password': user.original_password
        };
      }
      return {};
    });

    const worksheet = XLSX.utils.json_to_sheet(filteredUsers);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Users");
    XLSX.writeFile(workbook, "users.xlsx");
  };


  return (
    <div>
      <div className="container-fluid">
        <div className="page-title">
          <div className="row">
            <div className="col-sm-6 col-12">
              <h2>{content}</h2>
            </div>
            <div className="col-sm-6 col-12">
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><a href="index.html"><i className="fas fa-home"></i></a></li>
                <li className="breadcrumb-item">Dashboard /</li>

                <li className="breadcrumb-item active">{content}</li>
              </ol>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid table-space basic_table">
        <div className="row">

          <div className="col-sm-12">
            <div className="card overflow-hidden">
              <div className="card-header">

                <div className="row">
                  <div className="col-md-4 mt-3 mb-3 d-flex justify-content-start">
                    <button onClick={exportToExcel} className="btn btn-primary">Export to Excel</button>
                  </div>
                </div>



              </div>
              <div className="card-body">

                <div className="table-responsive">
                  {isLoading ? (
                    <p>Loading...</p>
                  ) : users.length === 0 ? (
                    <p>No data available</p>
                  ) : (
                    <table className="table">
                      <thead>
                        <tr className="border-bottom-secondary border-top-0">
                          <th scope="col">#</th>
                          {scope === '3' ? (
                            <>
                              <th scope="col" style={{ textAlign: 'left' }}>PEGID / NIK</th>
                              <th scope="col" style={{ textAlign: 'left' }}>NAMA</th>
                              <th scope="col" style={{ textAlign: 'left' }}>Username</th>
                              <th scope="col" style={{ textAlign: 'left' }}>Password</th>
                            </>
                          ) : scope === '2' ? (
                            <>
                              <th scope="col" style={{ textAlign: 'left' }}>Level</th>
                              <th scope="col" style={{ textAlign: 'left' }}>Rombel</th>
                              <th scope="col" style={{ textAlign: 'left' }}>Nama Siswa</th>
                              <th scope="col" style={{ textAlign: 'left' }}>Username</th>
                              <th scope="col" style={{ textAlign: 'left' }}>Password</th>
                            </>
                          ) : null}
                        </tr>
                      </thead>
                      <tbody>
                        {users.map((user, index) => (
                          <tr key={user.id}>
                            <td>{index + 1}</td>
                            {scope === '3' ? (
                              <>
                                <td style={{ textAlign: 'left' }}>{user.nik}</td>
                                <td style={{ textAlign: 'left' }}>{user.name}</td>
                                <td style={{ textAlign: 'left' }}>{user.email}</td>
                                <td style={{ textAlign: 'left' }}>{user.original_password}</td>
                              </>
                            ) : scope === '2' ? (
                              <>
                                <td style={{ textAlign: 'left' }}>{user.level}</td>
                                <td style={{ textAlign: 'left' }}>{user.rombel}</td>
                                <td style={{ textAlign: 'left' }}>{user.name}</td>
                                <td style={{ textAlign: 'left' }}>{user.email}</td>
                                <td style={{ textAlign: 'left' }}>{user.original_password}</td>
                              </>
                            ) : null}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                </div>
              </div>

            </div>
          </div>
        </div>





      </div>


    </div>
  )
}

export default UserMan
