import React, { useState, useEffect } from 'react'
import BottomNav from './BottomNav'
import './bottom.css';
import axios from 'axios'
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getMe } from "../../features/authSlice";


const DashboardUser = ({ url }) => {

  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isError } = useSelector((state) => state.auth);
  const [currentDateTime, setCurrentDateTime] = useState(new Date());
  const [absenku, setAbsenku] = useState([]);
  const [data, setData] = useState([])


  const fetchData = async () => {

    try {
      const response = await axios.get(url + "aplikasi");
      setData(response.data.data)
    } catch (error) {
      console.log(error);
    }

  };



  const fetchUser = async () => {
    setIsLoading(true);
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(url + "me", {
        headers: { Authorization: `Bearer ${token}` },
      });
      setUser(response.data.user);
    } catch (error) {
      if (error.response) {
        const message = error.response.data.message;
        // console.log(message);
      }
    } finally {
      setIsLoading(false);
    }
  };


  const fetchAbsensi = async () => {
    setIsLoading(true);
    const token = localStorage.getItem("token");
    if (user && user.role_id === '3') {
      // Jika Guru
      try {
        const response = await axios.post(url + "absensi-harian", {
          userId: user && user.reff_id,
          who: 'guru'
        }, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setAbsenku(response.data);
      } catch (error) {
        if (error.response) {
          const message = error.response.data.message;
          // console.log(message);
        }
      } finally {
        setIsLoading(false);
      }
    } else if (user && user.role_id === '2') {
      // Jika Siswa
      try {
        const response = await axios.post(url + "absensi-harian", {
          userId: user && user.reff_id,
          who: 'siswa'
        }, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setAbsenku(response.data);
      } catch (error) {
        if (error.response) {
          const message = error.response.data.message;
          // console.log(message);
        }
      } finally {
        setIsLoading(false);
      }
    }
  };





  useEffect(() => {
    dispatch(getMe());
  }, [dispatch]);

  useEffect(() => {
    if (isError) {
      navigate("/");
    }
  }, [isError, navigate]);

  useEffect(() => {
    fetchData();
    fetchUser();
  }, []);

  useEffect(() => {
    fetchAbsensi();
  }, [user]);


  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentDateTime(new Date());
    }, 1000);

    return () => clearInterval(timer);
  }, []);


  return (
    <div>

      <div className="container-fluid">
        <div className="page-title">
          <div className="row">
            <div className="col-sm-6 col-12">
              <h2>{data && data.nama_aplikasi}</h2>
            </div>

          </div>
        </div>
      </div>
      <div className="dashboard-container">
        <div className="content">

          <div className="container-fluid default-dashboard">
            <div className="row">
              <div className="col-12">
                <div className="card welcome-banner">

                  <div className="card-header p-0 card-no-border">

                    <div className="welcome-card" style={{ textAlign: 'center', marginBottom: '10px' }}>
                      <img width={80} src={data.logo} alt="" />
                      <div className="school-info">
                        <h1 className="school-name">{data.nama_sekolah}</h1>
                        <h5 className="school-address">{data.alamat}</h5>
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="d-flex align-center">
                      <h1>Assalamualaikum, <br />{user && user.name}  <img src="../assets/images/dashboard-1/hand.png" alt="" /></h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="card" style={{ backgroundColor: 'lightblue' }}>
                  <div className="card-header text-center" style={{ backgroundColor: 'lightblue' }}>Waktu Saat Ini </div>
                  <div className="card-body text-center" style={{ backgroundColor: 'lightblue', borderRadius: '20px' }}>

                    <h3>{currentDateTime.toLocaleDateString('id-ID', { month: 'long', day: 'numeric', year: 'numeric' })}</h3>

                    <h3>{currentDateTime.toLocaleTimeString()}</h3>

                  </div>
                </div>
              </div>
            </div>


            <div className="row">
              <h5 style={{ textAlign: 'center' }}>Absensi Hari Ini</h5>
              <div className="col-md-6 col-6 mt-3">
                <div className="card" style={{ backgroundColor: 'aqua' }}>
                  <div className="card-header text-center" style={{ backgroundColor: 'aqua' }}>Masuk</div>
                  <div className="card-body text-center" style={{ backgroundColor: 'aqua', borderRadius: '20px' }}><h3>{absenku.masuk}</h3></div>
                </div>
              </div>
              <div className="col-md-6 col-6 mt-3">
                <div className="card" style={{ backgroundColor: 'orange' }}>
                  <div className="card-header text-center" style={{ backgroundColor: 'orange' }}>Pulang</div>
                  <div className="card-body text-center" style={{ backgroundColor: 'orange', borderRadius: '20px' }}><h3>{absenku.pulang}</h3></div>
                </div>
              </div>
            </div>

          </div>
        </div>
        {/* Menu Mobile */}
        <BottomNav />
      </div>
    </div>
  )
}

export default DashboardUser
