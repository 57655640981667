import React, { useState, useEffect } from 'react'
import axios from 'axios'


const Footer = ({ url }) => {

  const [data, setData] = useState([])


  const fetchSistem = async () => {

    try {
      const response = await axios.get(url + "aplikasi");
      setData(response.data.data)
    } catch (error) {
      console.log(error);
    }

  };

  useEffect(() => {
    fetchSistem()
  }, []);



  return (
    <div>
      <footer className="footer">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6 footer-copyright">
              <p className="mb-0">Copyright {new Date().getFullYear()}  © {data && data.nama_aplikasi}.</p>
            </div>
            <div className="col-md-6">
              <p className="float-end mb-0">
                {data && data.nama_sekolah}
              </p>
            </div>
          </div>
        </div>
      </footer>
    </div>
  )
}

export default Footer
