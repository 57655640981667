import React, { useState, useEffect } from 'react'
import axios from 'axios'
import * as XLSX from 'xlsx';



const RekapAbsensi = ({ url, content }) => {

  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);
  const [selectedScope, setSelectedScope] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [rombel, setRombel] = useState([]);
  const [selectedRombel, setSelectedRombel] = useState('')
  const [users, setUsers] = useState([])
  const [attendanceData, setAttendanceData] = useState([]);


  const handleMonthChange = (event) => {
    setSelectedMonth(parseInt(event.target.value, 10));
  };

  const fetchRombelAll = async () => {
    setIsLoading(true)
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(url + "rombel", {
        headers: { Authorization: `Bearer ${token}` },
      });
      setRombel(response.data.data);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false)
  };

  const fetchDataUsers = async () => {
    setIsLoading(true);
    const token = localStorage.getItem("token");
    if (selectedScope === '2') {
      // Siswa
      try {
        const response = await axios.post(url + "get-isi-rombel", {
          rombelId: selectedRombel
        }, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setUsers(response.data.data);
      } catch (error) {
        console.log(error);
      }
    } else {
      // Guru
      try {
        const response = await axios.get(url + "guru", {
          headers: { Authorization: `Bearer ${token}` },
        });
        setUsers(response.data.data);
      } catch (error) {
        console.log(error);
      }

    }
    setIsLoading(false);
  };


  const fetchDataForAllUsers = async (month, year) => {
    setIsLoading(true);
    const token = localStorage.getItem("token");
    if (selectedScope === '2') {
      // Siswa
      try {
        const response = await axios.post(`${url}rekap/batch`, {
          month,
          year,
          selectedRombel,
        }, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setAttendanceData(response.data);
      } catch (error) {
        console.log(error);
      }
    } else {
      // Guru
      try {
        const response = await axios.post(`${url}rekap-guru/batch`, {
          month,
          year,
        }, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setAttendanceData(response.data);
      } catch (error) {
        console.log(error);
      }

    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (users.length > 0) {
      fetchDataForAllUsers(selectedMonth, new Date().getFullYear());
    }
  }, [users, selectedMonth]);


  useEffect(() => {
    fetchRombelAll();
  }, [selectedScope]);

  useEffect(() => {
    fetchDataUsers();
  }, [selectedScope === '2' ? selectedRombel : selectedScope]);




  const renderTableHeaders = (isStudentScope) => {
    const commonHeaders = [
      { label: '#', align: 'center' },
      { label: 'Nama', align: 'center' },
      { label: 'Hadir', align: 'center' },
      { label: 'Tepat Waktu', align: 'center' },
      { label: 'Terlambat', align: 'center' },
      { label: 'Pulang Cepat', align: 'center' },
    ];

    if (!isStudentScope) {
      commonHeaders.push({ label: 'Lembur', align: 'center' });
    }
    return (
      <thead>
        <tr className="border-bottom-secondary border-top-0">
          {commonHeaders.map((header, index) => (
            <th key={index} scope="col" style={{ textAlign: header.align }}>
              {header.label}
            </th>
          ))}
        </tr>
      </thead>
    );
  };


  const exportToExcel = () => {
    const monthNames = [
      'Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni',
      'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember'
    ];
    const selectedMonthName = monthNames[selectedMonth - 1];

    const dataToExport = [
      { Nama: 'Nama', Hadir: 'Hadir', Tepat: 'Tepat Waktu', Terlambat: 'Terlambat', 'Pulang Cepat': 'Pulang Cepat', Lembur: 'Lembur' }, // Add headers
      { Nama: `Bulan: ${selectedMonthName}` }, // Add month name as a header
      ...attendanceData.map(user => ({
        Nama: user.user || '',
        Hadir: user.masuk || '',
        Tepat: user.tepat || '',
        Terlambat: user.terlambat || '',
        'Pulang Cepat': user.pulangCepat || '',
        Lembur: selectedScope !== '2' ? (user.lembur || '') : ''
      }))
    ];

    const worksheet = XLSX.utils.json_to_sheet(dataToExport, { skipHeader: true });
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Rekap Absensi');
    XLSX.writeFile(workbook, `rekap-absensi.xlsx`);
  };


  return (
    <div>
      <div className="container-fluid">
        <div className="page-title">
          <div className="row">
            <div className="col-sm-6 col-12">
              <h2>{content}</h2>
            </div>
            <div className="col-sm-6 col-12">
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><a href="index.html"><i className="fas fa-home"></i></a></li>
                <li className="breadcrumb-item">Dashboard /</li>
                <li className="breadcrumb-item active">{content}</li>
              </ol>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid ">
        <div className="row">
          <div className="col-sm-12">
            <div className="card overflow-hidden">
              <div className="card-header">
                <div className='row'>
                  <div className="col-md-4 mb-3">
                    <label htmlFor="monthSelect">Pilih Bulan: </label>
                    <select id="monthSelect" className='form-control' value={selectedMonth} onChange={handleMonthChange}>
                      {['Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember'].map((month, index) => (
                        <option key={index + 1} value={index + 1}>
                          {month}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="col-md-4 mb-3">
                    <label htmlFor="scopeData">Scope Data </label>
                    <select id="scopeData" className='form-control' value={selectedScope} onChange={(e) => setSelectedScope(e.target.value)}>
                      <option value=''>Pilih Scope</option>
                      <option value='1'>Guru / Karyawan</option>
                      <option value='2'>Siswa</option>
                    </select>
                  </div>

                  {selectedScope === '2' && (
                    <div className="col-md-4 mb-3">
                      <label htmlFor="selectRombel">Pilih Rombel </label>
                      <select id="selectRombel" className='form-control' value={selectedRombel} onChange={(e) => setSelectedRombel(e.target.value)}>
                        <option value=''>Pilih Rombel</option>
                        {rombel.map((item, index) => (
                          <option key={index} value={item.id}>
                            {item.nama_rombel}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}

                  <button
                    onClick={exportToExcel}
                    className="btn btn-primary"
                  >
                    Export Data to Excel
                  </button>
                </div>
              </div>
              <div className="card-body">
                <div className="table-responsive">
                  <table className="table">
                    {renderTableHeaders(selectedScope === '2')}
                    <tbody>
                      {isLoading ? (
                        <tr>
                          <td colSpan="6" style={{ textAlign: 'center' }}>Loading...</td>
                        </tr>
                      ) : users.length === 0 ? (
                        <tr>
                          <td colSpan="6" style={{ textAlign: 'center' }}>No data available</td>
                        </tr>
                      ) : (
                        attendanceData.map((userData, userIndex) => (
                          <tr key={userIndex}>
                            <td style={{ textAlign: 'center' }}>{userIndex + 1}</td>
                            <td style={{ textAlign: 'center' }}>{userData.user}</td>
                            <td style={{ textAlign: 'center' }}>{userData.masuk}</td>
                            <td style={{ textAlign: 'center' }}>{userData.tepat}</td>
                            <td style={{ textAlign: 'center' }}>{userData.terlambat}</td>
                            <td style={{ textAlign: 'center' }}>{userData.pulangCepat}</td>

                            {selectedScope !== '2' && (
                              <td style={{ textAlign: 'center' }}>{userData.lembur}</td>
                            )}
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>



    </div>
  )
}

export default RekapAbsensi
