import React, { useState, useEffect } from 'react'
import axios from 'axios'
import BottomNav from './BottomNav'
import './bottom.css';

const AbsensiUser = ({ url }) => {

  const [data, setData] = useState([])
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);
  const [attendanceData, setAttendanceData] = useState({});
  const [isLoading, setIsLoading] = useState(false)



  const fetchData = async () => {
    try {
      const response = await axios.get(url + "aplikasi");
      setData(response.data.data)
    } catch (error) {
      console.log(error);
    }
  };

  const handleMonthChange = (event) => {
    setSelectedMonth(parseInt(event.target.value, 10));
  };

  const generateDatesForMonth = (month) => {
    const year = new Date().getFullYear();
    const daysInMonth = new Date(year, month, 0).getDate();
    return Array.from({ length: daysInMonth }, (_, i) => i + 1);
  };


  const fetchDataForUser = async (month, year) => {
    setIsLoading(true);
    const token = localStorage.getItem("token");
    if (localStorage.getItem('role') === '2') {
      // Siswa
      try {
        const response = await axios.post(`${url}absen-detail-sw/batch`, {
          month,
          year,
          email: localStorage.getItem('email')
        }, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setAttendanceData(response.data[0]);
      } catch (error) {
        console.log(error);
      }
    } else if (localStorage.getItem('role') === '3') {
      // Guru
      try {
        const response = await axios.post(`${url}absen-detail-gr/batch`, {
          month,
          year,
          email: localStorage.getItem('email')
        }, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setAttendanceData(response.data[0]);
      } catch (error) {
        console.log(error);
      }

    }
    setIsLoading(false);
  };


  useEffect(() => {

    fetchDataForUser(selectedMonth, new Date().getFullYear());

  }, [selectedMonth]);



  useEffect(() => {
    fetchData();

  }, []);

  useEffect(() => {
    console.log('Updated Attendance Data:', attendanceData);
  }, [attendanceData]);


  return (
    <div>
      <div className="container-fluid">
        <div className="page-title">
          <div className="row">
            <div className="col-sm-6 col-12">
              <h2>{data && data.nama_aplikasi}</h2>
            </div>

          </div>
        </div>
      </div>
      <div className="dashboard-container main-content-ku">
        <div className="content">

          <div className="container-fluid">
            <div className="row">
              <div className="col-12">

                <h3>Data Absensi</h3>

                <div className="col-md-4 mb-3 mt-3">
                  <label htmlFor="monthSelect">Pilih Bulan: </label>
                  <select id="monthSelect" className='form-control' value={selectedMonth} onChange={handleMonthChange}>
                    {['Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember'].map((month, index) => (
                      <option key={index + 1} value={index + 1}>
                        {month}
                      </option>
                    ))}
                  </select>

                </div>

                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr className="border-bottom-secondary border-top-0">
                          <th scope="col">Tanggal</th>
                          <th scope="col">Jam Masuk</th>
                          <th scope="col">Jam Pulang</th>
                        </tr>
                      </thead>
                      <tbody>
                        {generateDatesForMonth(selectedMonth).map((day) => (
                          <tr key={day}>
                            <td>{day}</td>
                            <td>
                              {attendanceData.masuk && attendanceData.masuk[day.toString()]
                                ? attendanceData.masuk[day.toString()]
                                : '-'}
                            </td>
                            <td>
                              {attendanceData.pulang && attendanceData.pulang[day.toString()]
                                ? attendanceData.pulang[day.toString()]
                                : '-'}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>




              </div>
            </div>





          </div>
        </div>
      </div>
      {/* Menu Mobile */}
      <BottomNav />
    </div>
  )
}

export default AbsensiUser
