
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { LoginUser, reset } from "../../features/authSlice";
import axios from 'axios';


const Auth = ({ url }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [data, setData] = useState([])


  const fetchSistem = async () => {

    try {
      const response = await axios.get(url + "aplikasi");
      setData(response.data.data)
    } catch (error) {
      console.log(error);
    }

  };

  useEffect(() => {
    fetchSistem()
  }, []);



  const dispatch = useDispatch();
  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const { user, isLoading, isSuccess, isError, message } = useSelector(
    (state) => state.auth
  );

  useEffect(() => {
    if (user || isSuccess) {
      if (user.user.role_id === '1') {
        navigate("/dashboard");
      } else if (user.user.role_id === '2' || user.user.role_id === '3') {
        navigate("/dashboard-user");
      }
    }
    dispatch(reset());
  }, [user, isSuccess, navigate, dispatch]);


  const Auth = (e) => {
    e.preventDefault();
    dispatch(LoginUser({ email, password }));
  };


  return (
    <div className="container-fluid p-0">
      <div className="row m-0">
        <div className="col-12 p-0">
          <div className="login-card login-dark">
            <div>
              <div><a className="logo" href="index.html">
                <img className="img-fluid for-light m-auto" src={data && data.logo_aplikasi} alt="looginpage" />
                <img className="img-fluid for-dark" src={data && data.logo_aplikasi} alt="logo" /></a></div>
              <h2 className="text-center mb-3">{data && data.nama_sekolah}</h2>
              <div className="login-main">
                <form className="theme-form" onSubmit={Auth}>
                  <h2 className="text-center">Sign in to account</h2>
                  <p className="text-center">Masukkan username dan password untuk login</p>

                  {isError && (
                    <div
                      className="alert alert-danger alert-dismissible fade show"
                      role="alert"
                    >
                      <strong>{message}</strong>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="alert"
                        aria-label="Close"
                      ></button>
                    </div>
                  )}


                  <div className="form-group">
                    <label className="col-form-label" htmlFor="email">Email Address</label>
                    <input className="form-control" id='email' type="email" required="" placeholder="Test@gmail.com" value={email} onChange={(e) => setEmail(e.target.value)} />
                  </div>

                  <div className="form-group">
                    <label className="col-form-label" htmlFor="password">Password</label>
                    <div className="form-input position-relative">
                      <input
                        className="form-control" id='password'
                        type={showPassword ? "text" : "password"}
                        name="login[password]"
                        required=""
                        placeholder="*********" value={password} onChange={(e) => setPassword(e.target.value)}
                      />
                      <div className="show-hide">
                        <span onClick={togglePasswordVisibility}>
                          {showPassword ? "Hide" : "Show"}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="form-group mb-0 checkbox-checked">

                    <div className="text-end mt-3">
                      {isLoading ? <button className="btn btn-primary btn-block w-100" disabled>Loading... </button> : <button className="btn btn-primary btn-block w-100" type="submit">Sign in </button>}
                    </div>
                  </div>


                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Auth
