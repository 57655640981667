import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import $ from 'jquery';
import 'datatables.net';
import 'datatables.net-dt/css/dataTables.dataTables.css';

const DataLog = ({ url, content }) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [filter, setFilter] = useState('');
  const tableRef = useRef(null);

  const fetchData = async () => {
    setIsLoading(true);
    const token = localStorage.getItem("token");
    try {

      const formattedDate = filter ? new Date(filter).toISOString().slice(0, 10) : '';
      const response = await axios.post(url + "log-all", {
        date: formattedDate
      }, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setData(response.data.data);
    } catch (error) {
      console.log(error.response.data.message);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (!isLoading && data.length > 0) {
      if ($.fn.DataTable.isDataTable(tableRef.current)) {
        $(tableRef.current).DataTable().clear().destroy();
      }

      const table = $(tableRef.current).DataTable({
        destroy: true,
      });

      $('#searchName').on('keyup', function () {
        table.columns(3).search(this.value).draw();
      });

      return () => {
        if ($.fn.DataTable.isDataTable(tableRef.current)) {
          table.destroy();
        }
      };
    }
  }, [isLoading, data]);

  useEffect(() => {
    fetchData();
  }, [filter]);

  return (
    <div>
      <div className="container-fluid">
        <div className="page-title">
          <div className="row">
            <div className="col-sm-6 col-12">
              <h2>{content}</h2>
            </div>
            <div className="col-sm-6 col-12">
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><a href="index.html"><i className="fas fa-home"></i></a></li>
                <li className="breadcrumb-item">Dashboard /</li>
                <li className="breadcrumb-item active">{content}</li>
              </ol>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid table-space basic_table">
        <div className="row">
          <div className="col-sm-12">
            <div className="card overflow-hidden">


              <div className="card-body">
                <div className="table-responsive">
                  {isLoading ? (
                    <div className="text-center">
                      <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    </div>
                  ) : data.length > 0 ? (
                    <table className="display" id="basic-1" ref={tableRef}>
                      <thead>
                        <tr className="border-bottom-secondary border-top-0">
                          <th scope="col" style={{ textAlign: 'center' }}>#</th>
                          <th scope="col" style={{ textAlign: 'center' }}>Tanggal</th>
                          <th scope="col" style={{ textAlign: 'center' }}>Nama</th>
                          <th scope="col" style={{ textAlign: 'center' }}>Log</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.map((item, index) => (
                          <tr key={index}>
                            <td style={{ textAlign: 'center' }}>{index + 1}</td>
                            <td style={{ textAlign: 'center' }}>{item.date}</td>
                            <td style={{ textAlign: 'left' }}>{item.name}</td>
                            <td style={{ textAlign: 'center' }}>{item.time}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <div className="text-center">No data available</div>
                  )}
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DataLog;