import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './mystyles.css';
import * as XLSX from 'xlsx';


const DataAbsensi = ({ content, url }) => {
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);
  const [selectedScope, setSelectedScope] = useState('');
  const [users, setUsers] = useState([]);
  const [attendanceData, setAttendanceData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [rombel, setRombel] = useState([]);
  const [selectedRombel, setSelectedRombel] = useState('')


  const handleMonthChange = (event) => {
    setSelectedMonth(parseInt(event.target.value, 10));
  };

  const getDaysInMonth = (month, year) => {
    return new Date(year, month, 0).getDate();
  };

  const renderDatesHeader = () => {
    const year = new Date().getFullYear();
    const daysInMonth = getDaysInMonth(selectedMonth, year);
    const dates = [];
    for (let day = 1; day <= daysInMonth; day++) {
      const date = new Date(year, selectedMonth - 1, day);
      const isSunday = date.getDay() === 0; // 0 represents Sunday
      dates.push(
        <th key={day} style={{ textAlign: 'center', backgroundColor: isSunday ? 'red' : 'transparent', width: '20px' }}>
          {day}
        </th>
      );
    }
    return dates;
  };


  const fetchRombelAll = async () => {
    setIsLoading(true)
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(url + "rombel", {
        headers: { Authorization: `Bearer ${token}` },
      });
      setRombel(response.data.data);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false)
  };





  const fetchDataUsers = async () => {
    setIsLoading(true);
    const token = localStorage.getItem("token");
    if (selectedScope === '2') {
      // Untuk Siswa
      try {
        const response = await axios.post(url + "get-isi-rombel", {
          rombelId: selectedRombel
        }, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setUsers(response.data.data);
      } catch (error) {
        console.log(error);
      }
    } else {
      // Untuk Guru
      try {
        const response = await axios.get(url + "guru", {
          headers: { Authorization: `Bearer ${token}` },
        });
        setUsers(response.data.data);
      } catch (error) {
        console.log(error);
      }
    }
    setIsLoading(false);
  };

  const fetchDataForAllUsers = async (month, year) => {
    setIsLoading(true);
    const token = localStorage.getItem("token");
    if (selectedScope === '2') {
      // Untuk Siswa
      try {
        const response = await axios.post(`${url}absen/batch`, {
          month,
          year,
          selectedRombel,
        }, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setAttendanceData(response.data);
      } catch (error) {
        console.log(error);
      }
    } else {
      // Untuk Guru
      try {
        const response = await axios.post(`${url}absen-guru/batch`, {
          month,
          year,
        }, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setAttendanceData(response.data);
      } catch (error) {
        console.log(error);
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchRombelAll();
  }, [selectedScope]);

  useEffect(() => {
    fetchDataUsers();
  }, [selectedScope === '2' ? selectedRombel : selectedScope]);

  useEffect(() => {
    if (users.length > 0) {
      fetchDataForAllUsers(selectedMonth, new Date().getFullYear());
    }
  }, [users, selectedMonth]);


  const exportToExcel = () => {
    const year = new Date().getFullYear();
    const daysInMonth = getDaysInMonth(selectedMonth, year);

    // Create headers for the Excel file
    const monthName = ['Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember'][selectedMonth - 1];
    const headers = ['Nama'];
    for (let day = 1; day <= daysInMonth; day++) {
      headers.push(day.toString());
    }

    // Prepare data for export
    const dataForExport = attendanceData.map(userData => {
      const row = [userData.user];
      for (let day = 1; day <= daysInMonth; day++) {
        const status = userData.attendance[day] || ''; // Default to empty if no data
        row.push(status);
      }
      return row;
    });

    // Combine headers and data
    const worksheetData = [[`Bulan: ${monthName}`], headers, ...dataForExport];

    // Create worksheet and workbook
    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);

    // Style Sundays
    for (let day = 1; day <= daysInMonth; day++) {
      const date = new Date(year, selectedMonth - 1, day);
      if (date.getDay() === 0) { // 0 represents Sunday
        for (let row = 0; row <= dataForExport.length; row++) { // Start from 0 to include headers
          const cellAddress = XLSX.utils.encode_cell({ c: day, r: row + 1 }); // Adjust row index
          if (!worksheet[cellAddress]) {
            worksheet[cellAddress] = { t: 's', v: '' }; // Ensure cell exists
          }
          worksheet[cellAddress].s = {
            fill: {
              patternType: "solid", // Ensure pattern type is set
              fgColor: { rgb: "FF0000" } // Red color
            }
          };
        }
      }
    }

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "AttendanceData");

    // Write to file
    XLSX.writeFile(workbook, "AttendanceData.xlsx");
  };


  return (
    <div>
      <div className="container-fluid">
        <div className="page-title">
          <div className="row">
            <div className="col-sm-6 col-12">
              <h2>{content}</h2>
            </div>
            <div className="col-sm-6 col-12">
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><a href="index.html"><i className="fas fa-home"></i></a></li>
                <li className="breadcrumb-item">Dashboard /</li>
                <li className="breadcrumb-item active">{content}</li>
              </ol>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid ">
        <div className="row">
          <div className="col-sm-12">
            <div className="card overflow-hidden">
              <div className="card-header">
                <div className='row'>
                  <div className="col-md-4 mb-3">
                    <label htmlFor="monthSelect">Pilih Bulan: </label>
                    <select id="monthSelect" className='form-control' value={selectedMonth} onChange={handleMonthChange}>
                      {['Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember'].map((month, index) => (
                        <option key={index + 1} value={index + 1}>
                          {month}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="col-md-4 mb-3">
                    <label htmlFor="scopeData">Scope Data </label>
                    <select id="scopeData" className='form-control' value={selectedScope} onChange={(e) => setSelectedScope(e.target.value)}>
                      <option value=''>Pilih Scope</option>
                      <option value='1'>Guru / Karyawan</option>
                      <option value='2'>Siswa</option>
                    </select>
                  </div>

                  {selectedScope === '2' && (
                    <div className="col-md-4 mb-3">
                      <label htmlFor="selectRombel">Pilih Rombel </label>
                      <select id="selectRombel" className='form-control' value={selectedRombel} onChange={(e) => setSelectedRombel(e.target.value)}>
                        <option value=''>Pilih Rombel</option>
                        {rombel.map((item, index) => (
                          <option key={index} value={item.id}>
                            {item.nama_rombel}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}

                  <button onClick={exportToExcel} className="btn btn-primary">Export to Excel</button>
                </div>
              </div>
              <div className="card-body">
                <div className="table-responsive">

                  <table className="table">
                    <thead>
                      <tr className="border-bottom-secondary border-top-0">
                        <th scope="col" style={{ textAlign: 'center' }}>#</th>
                        <th scope="col" style={{ textAlign: 'center', width: '130px' }}>Nama</th>
                        <th scope="col" colSpan={getDaysInMonth(selectedMonth, new Date().getFullYear())} style={{ textAlign: 'center' }}>
                          Bulan {['Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember'][selectedMonth - 1]}
                        </th>
                      </tr>
                      <tr>
                        <th></th>
                        <th></th>
                        {renderDatesHeader()}
                      </tr>
                    </thead>
                    <tbody>
                      {isLoading ? (
                        <tr>
                          <td colSpan={getDaysInMonth(selectedMonth, new Date().getFullYear()) + 2} style={{ textAlign: 'center' }}>
                            Loading...
                          </td>
                        </tr>
                      ) : users.length === 0 ? (
                        <tr>
                          <td colSpan={getDaysInMonth(selectedMonth, new Date().getFullYear()) + 2} style={{ textAlign: 'center' }}>
                            No data available
                          </td>
                        </tr>
                      ) : (
                        attendanceData.map((userData, userIndex) => (
                          <tr key={userIndex}>
                            <td style={{ textAlign: 'center' }}>{userIndex + 1}</td>
                            <td style={{ textAlign: 'center' }}>{userData.user}</td>
                            {Object.values(userData.attendance).map((status, index) => (
                              <td key={index} style={{ textAlign: 'center' }}>

                                <span
                                  className={`badge ${status === '✔️' ? 'badge-success' : 'badge-danger'} badge-custom`}
                                >
                                  {status}
                                </span>

                              </td>
                            ))}
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DataAbsensi;