import React, { useEffect, useState } from 'react'
import axios from 'axios'



const DashboardAdmin = ({ url }) => {

  const [user, setUser] = useState([])
  const [guru, setGuru] = useState([])
  const [siswa, setSiswa] = useState([])
  const [rombel, setRombel] = useState([])

  const [isLoading, setIsLoading] = useState(false)


  const fetchUser = async () => {
    setIsLoading(true);
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(url + "me", {
        headers: { Authorization: `Bearer ${token}` },
      });
      setUser(response.data.user);
    } catch (error) {
      if (error.response) {
        const message = error.response.data.message;
        // console.log(message);
      }
    } finally {
      setIsLoading(false);
    }

  };

  const fetchGuru = async () => {
    setIsLoading(true);
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(url + "jmlguru", {
        headers: { Authorization: `Bearer ${token}` },
      });
      setGuru(response.data.data);
    } catch (error) {
      if (error.response) {
        const message = error.response.data.message;
        // console.log(message);
      }
    } finally {
      setIsLoading(false);
    }

  };

  const fetchRombel = async () => {
    setIsLoading(true);
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(url + "jmlrombel", {
        headers: { Authorization: `Bearer ${token}` },
      });
      setRombel(response.data.data);
    } catch (error) {
      if (error.response) {
        const message = error.response.data.message;
        // console.log(message);
      }
    } finally {
      setIsLoading(false);
    }

  };


  const fetchSiswa = async () => {
    setIsLoading(true);
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(url + "jmlsiswa", {
        headers: { Authorization: `Bearer ${token}` },
      });
      setSiswa(response.data.data);
    } catch (error) {
      if (error.response) {
        const message = error.response.data.message;
        // console.log(message);
      }
    } finally {
      setIsLoading(false);
    }

  };


  useEffect(() => {
    fetchUser();
    fetchGuru();
    fetchSiswa();
    fetchRombel();
  }, []);



  return (
    <div>

      <div className="container-fluid">
        <div className="page-title">
          <div className="row">
            <div className="col-sm-6 col-12">
              <h2>Dashboard</h2>

            </div>
            <div className="col-sm-6 col-12">
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><a href="index.html"><i className="fas fa-home"></i></a></li>
                <li className="breadcrumb-item">Dashboard</li>
                <li className="breadcrumb-item active">Admin</li>
              </ol>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid default-dashboard">
        <div className="row">
          <div className="col-12">
            <div className="card welcome-banner">
              <div className="card-header p-0 card-no-border">
                <div className="welcome-card"><img className="w-100 img-fluid" src="../assets/images/dashboard-1/welcome-bg.png" alt="" /><img className="position-absolute img-1 img-fluid" src="../assets/images/dashboard-1/img-1.png" alt="" /><img className="position-absolute img-2 img-fluid" src="../assets/images/dashboard-1/img-2.png" alt="" /><img className="position-absolute img-3 img-fluid" src="../assets/images/dashboard-1/img-3.png" alt="" /><img className="position-absolute img-4 img-fluid" src="../assets/images/dashboard-1/img-4.png" alt="" /><img className="position-absolute img-5 img-fluid" src="../assets/images/dashboard-1/img-5.png" alt="" /></div>
              </div>
              <div className="card-body">
                <div className="d-flex align-center">
                  <h1>Hello, {user && user.name}  <img src="../assets/images/dashboard-1/hand.png" alt="" /></h1>
                </div>
                <p> Welcome back!</p>

              </div>
            </div>
          </div>
          <div className="col-xxl-3 col-xl-4 proorder-xxl-2 col-sm-6 box-col-6">
            <div className="card earning-card">
              <div className="card-header pb-0 card-no-border">
                <div className="header-top">
                  <h3>Guru / Karyawan</h3>
                </div>
              </div>
              <div className="card-body pb-0">
                <div className="d-flex align-center gap-3">
                  <h2>{guru}</h2>
                </div>

              </div>
            </div>
          </div>
          <div className="col-xxl-3 col-xl-4 proorder-xxl-2 col-sm-6 box-col-6">
            <div className="card earning-card">
              <div className="card-header pb-0 card-no-border">
                <div className="header-top">
                  <h3>Rombel</h3>
                </div>
              </div>
              <div className="card-body pb-0">
                <div className="d-flex align-center gap-3">
                  <h2>{rombel}</h2>
                </div>

              </div>
            </div>
          </div>
          <div className="col-xxl-3 col-xl-4 proorder-xxl-2 col-sm-6 box-col-6">
            <div className="card earning-card">
              <div className="card-header pb-0 card-no-border">
                <div className="header-top">
                  <h3>Siswa</h3>
                </div>
              </div>
              <div className="card-body pb-0">
                <div className="d-flex align-center gap-3">
                  <h2>{siswa}</h2>
                </div>

              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

  )
}

export default DashboardAdmin
