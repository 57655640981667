import {
  BrowserRouter as Router,
  Routes,
  Route,
  useParams,
  Navigate,
} from "react-router-dom";
import Auth from './components/login/Auth';
import Dashboard from "./pages/Dashboard";
import Master from "./pages/Master";
import Setting from "./pages/Setting";
import Landing from "./pages/Landing";
import Absensi from "./pages/Absensi";
import DashboardUser from "./components/user/DashboardUser";
import AbsensiUser from "./components/user/AbsensiUser";
import RekapAbsenUser from "./components/user/RekapAbsenUser";
import UserManager from "./pages/UserManager";


function App() {
  // const url = 'http://localhost:8000/api/';
  const url = 'https://api-absen.mamuda.my.id/api/';



  return (
    <Router>
      <Routes>
        <Route path="/" element={<Landing url={url} />} />
        <Route path="/login" element={<Auth url={url} />} />
        <Route path="/dashboard" element={<Dashboard url={url} />} />
        <Route path="/master-tp" element={<Master content={'Tahun Pelajaran & Semester'} url={url} />} />
        <Route path="/master-rombel" element={<Master content={'Data Rombel'} url={url} />} />
        <Route path="/detail-rombel/:nama_rombel/:rombel_id" element={<Master content={'Detail Rombel'} url={url} />} />
        <Route path="/master-siswa" element={<Master content={'Data Siswa'} url={url} />} />
        <Route path="/master-guru" element={<Master content={'Data Guru'} url={url} />} />

        <Route path="/setting-absensi" element={<Setting content={'Setting Jam Kerja'} url={url} />} />
        <Route path="/setting-aplikasi" element={<Setting content={'Setting Aplikasi'} url={url} />} />
        <Route path="/absensi-log" element={<Absensi content={'Data Log Kehadiran'} url={url} />} />
        <Route path="/absensi-data" element={<Absensi content={'Data Absensi'} url={url} />} />
        <Route path="/absensi-detail" element={<Absensi content={'Data Detail Absensi'} url={url} />} />
        <Route path="/absensi-rekap" element={<Absensi content={'Rekap Data Absensi'} url={url} />} />

        <Route path="/dashboard-user" element={<DashboardUser url={url} />} />
        <Route path="/absensi-user" element={<AbsensiUser url={url} />} />
        <Route path="/rekapabsen-user" element={<RekapAbsenUser url={url} />} />
        <Route path="/userman-guru" element={<UserManager content={'Guru'} url={url} />} />
        <Route path="/userman-siswa" element={<UserManager content={'Siswa'} url={url} />} />
      </Routes>
    </Router>
  );
}

export default App;
