import React, { createContext, useState, useEffect } from 'react';

export const SidebarContext = createContext();

export const SidebarProvider = ({ children }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    console.log('Toggling sidebar');

    const sidebarToggle = document.querySelector(".toggle-sidebar");
    const wrapper = document.querySelector(".page-wrapper");

    // sidebarToggle.addEventListener("click", function () {
    wrapper.classList.toggle("sidebar-open");
    const wrapperClose = wrapper.classList.contains("sidebar-open");
    // });

  };


  return (
    <SidebarContext.Provider value={{ isSidebarOpen, toggleSidebar }}>
      {children}
    </SidebarContext.Provider>
  );
};