import React, { useState, useEffect } from 'react'
import Layouts from './Layouts'
import MasterTp from '../components/admin/MasterTp'
import axios from 'axios'
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getMe } from "../features/authSlice";
import MasterRombel from '../components/admin/MasterRombel';
import MasterSiswa from '../components/admin/MasterSiswa';
import DetailRombel from '../components/admin/DetailRombel';
import MasterGuru from '../components/admin/MasterGuru';

const Master = ({ url, content }) => {

  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isError } = useSelector((state) => state.auth);


  const fetchUser = async () => {
    setIsLoading(true);
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(url + "me", {
        headers: { Authorization: `Bearer ${token}` },
      });
      setUser(response.data.user);
    } catch (error) {
      if (error.response) {
        const message = error.response.data.message;
        // console.log(message);
      }
    } finally {
      setIsLoading(false);
    }

  };

  useEffect(() => {
    dispatch(getMe());
  }, [dispatch]);

  useEffect(() => {
    if (isError) {
      navigate("/");
    }
  }, [isError, navigate]);

  useEffect(() => {
    fetchUser();
  }, []);


  return (
    <div>
      <Layouts user={user && user} url={url}>
        {content === 'Tahun Pelajaran & Semester' ? <MasterTp content={content} url={url} /> : null}
        {content === 'Data Rombel' ? <MasterRombel content={content} url={url} /> : null}
        {content === 'Data Siswa' ? <MasterSiswa content={content} url={url} /> : null}
        {content === 'Data Guru' ? <MasterGuru content={content} url={url} /> : null}
        {content === 'Detail Rombel' ? <DetailRombel content={content} url={url} /> : null}
      </Layouts>
    </div>
  )
}

export default Master
