import React, { useEffect, useState, useContext } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { SidebarContext } from './SidebarContext';
import {
  LogOut,
  reset
} from "../../features/authSlice";
import axios from "axios";



const Header = ({ url }) => {
  const { toggleSidebar } = useContext(SidebarContext);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [data, setData] = useState([])


  const fetchData = async () => {

    try {
      const response = await axios.get(url + "aplikasi");
      setData(response.data.data)
    } catch (error) {
      console.log(error);
    }

  };





  const handleLogout = () => {
    dispatch(LogOut());
    dispatch(reset());
    localStorage.removeItem("token");
    localStorage.removeItem("name");
    localStorage.removeItem("role");
    localStorage.removeItem("email");
    navigate("/");
  };

  useEffect(() => {
    fetchData();

  }, []);


  return (
    <div>
      <header className="page-header row">
        <div className="logo-wrapper d-flex align-items-center col-auto">
          <a href="#"><img className="light-logo img-fluid" src={data && data.logo_aplikasi} alt="logo" />
            <img className="dark-logo img-fluid" src={data && data.logo_aplikasi} alt="logo" />
          </a>
          <a className="close-btn toggle-sidebar" href="#" onClick={toggleSidebar}>
            <i className="fa-solid fa-bars" aria-hidden="true"></i>
          </a>
        </div>


        <div className="page-main-header col">
          <div className="header-left">
            <form className="form-inline search-full col" action="#" method="get">
              <div className="form-group w-100">
                <div className="Typeahead Typeahead--twitterUsers">
                  <div className="u-posRelative">
                    <input className="demo-input Typeahead-input form-control-plaintext w-100" type="text" placeholder="Search Admiro .." name="q" title="" autofocus="autofocus" />
                    <div className="spinner-border Typeahead-spinner" role="status"><span className="sr-only">Loading...</span></div><i className="close-search" data-feather="x"></i>
                  </div>
                  <div className="Typeahead-menu"></div>
                </div>
              </div>
            </form>
            <div className="form-group-header d-lg-block d-none">
              <div className="Typeahead Typeahead--twitterUsers">
                <div className="u-posRelative d-flex align-items-center">
                  <input className="demo-input py-0 Typeahead-input form-control-plaintext w-100" type="text" placeholder="Type to Search..." name="q" title="" /><i className="search-bg iconly-Search icli"></i>
                </div>
              </div>
            </div>
          </div>
          <div className="nav-right">
            <ul className="header-right">

              <li className="profile-nav custom-dropdown">
                <div className="user-wrap">
                  <div className="user-img"><img src="../assets/images/profile.png" alt="user" /></div>
                  <div className="user-content">
                    <h6>Administrator</h6>
                    <p className="mb-0">Admin<i className="fa-solid fa-chevron-down"></i></p>
                  </div>
                </div>
                <div className="custom-menu overflow-hidden">
                  <ul className="profile-body">
                    {/* <li className="d-flex">
                      <svg className="svg-color">
                        <use href="https://admin.pixelstrap.net/admiro/assets/svg/iconly-sprite.svg#Profile"></use>
                      </svg><a className="ms-2" href="user-profile.html">Account</a>
                    </li>
                    <li className="d-flex">
                      <svg className="svg-color">
                        <use href="https://admin.pixelstrap.net/admiro/assets/svg/iconly-sprite.svg#Message"></use>
                      </svg><a className="ms-2" href="letter-box.html">Inbox</a>
                    </li>
                    <li className="d-flex">
                      <svg className="svg-color">
                        <use href="https://admin.pixelstrap.net/admiro/assets/svg/iconly-sprite.svg#Document"></use>
                      </svg><a className="ms-2" href="to-do.html">Task</a>
                    </li> */}
                    <li className="d-flex">
                      <svg className="svg-color">
                        <use href="https://admin.pixelstrap.net/admiro/assets/svg/iconly-sprite.svg#Login"></use>
                      </svg>
                      <a className="ms-2" onClick={handleLogout} >Log Out</a>

                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </header>
    </div>
  )
}

export default Header
