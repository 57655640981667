// src/components/BottomNav.jsx
import React, { useState, useEffect } from 'react';
import { FaHome, FaChartPie, FaListUl, FaSignInAlt } from 'react-icons/fa';
import './bottom.css';
import { NavLink, useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

import {
  LogOut,
  reset
} from "../../features/authSlice";
import axios from "axios";




const BottomNav = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const handleNavClick = (index) => {
    setActiveIndex(index);
  };


  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    dispatch(LogOut());
    dispatch(reset());
    localStorage.removeItem("token");
    localStorage.removeItem("name");
    localStorage.removeItem("role");
    localStorage.removeItem("email");
    navigate("/");
  };



  return (
    <div className="bottom-nav">
      <ul className="nav-list">
        <li className={`nav-item ${activeIndex === 0 ? 'active' : ''}`} onClick={() => handleNavClick(0)}>
          <NavLink to="/dashboard-user">
            <FaHome />
            <span>Home</span>
          </NavLink>
        </li>

        <li className={`nav-item ${activeIndex === 1 ? 'active' : ''}`} onClick={() => handleNavClick(1)}>
          <NavLink to="/absensi-user">
            <FaChartPie />
            <span>Data Absensi </span>
          </NavLink>
        </li>
        <li className={`nav-item ${activeIndex === 2 ? 'active' : ''}`} onClick={() => handleNavClick(2)}>
          <NavLink to="/rekapabsen-user">
            <FaListUl />
            <span>Rekapitulasi</span>
          </NavLink>
        </li>
        <li className={`nav-item ${activeIndex === 3 ? 'active' : ''}`} onClick={() => handleNavClick(3)}>
          <a onClick={handleLogout}>
            <FaSignInAlt />
            <span>Logout</span>
          </a>
        </li>
      </ul>
    </div>
  );
};

export default BottomNav;