import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios';
import swal from 'sweetalert';



const DetailRombel = ({ url, content }) => {
  const { nama_rombel, rombel_id } = useParams();
  const [dataSiswa, setDataSiswa] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const [searchTerm, setSearchTerm] = useState('');
  const [selectAll, setSelectAll] = useState(false);
  const [selectedSiswa, setSelectedSiswa] = useState([]);

  const [siswaRombel, setSiswaRombel] = useState([])


  const navigate = useNavigate();



  const handleCheckboxChange = (siswaId) => {
    setSelectedSiswa(prevSelected =>
      prevSelected.includes(siswaId)
        ? prevSelected.filter(id => id !== siswaId)
        : [...prevSelected, siswaId]
    );
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedSiswa([]);
    } else {
      setSelectedSiswa(dataSiswa.map(siswa => siswa.id));
    }
    setSelectAll(!selectAll);
  };


  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredDataSiswa = dataSiswa.filter(siswa =>
    siswa.nama.toLowerCase().includes(searchTerm.toLowerCase())
  );



  const fetchSiswaRombel = async () => {
    setIsLoading(true)
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(url + "get-isi-rombel", { rombelId: rombel_id }, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setSiswaRombel(response.data.data);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false)
  };

  const fetchSiswa = async () => {
    setIsLoading(true)
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(url + "siswa", {
        headers: { Authorization: `Bearer ${token}` },
      });
      setDataSiswa(response.data.data);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false)
  };


  // Simpan
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (selectedSiswa.length === 0) {
      swal({
        icon: 'error',
        title: 'Oops...',
        text: 'Pilih siswa terlebih dahulu!',
      });
    } else {
      console.log('Selected student IDs:', selectedSiswa);
      try {
        const token = localStorage.getItem("token");
        const response = await axios.post(url + "add-isi-rombel", {
          rombel_id: rombel_id,
          student_ids: selectedSiswa
        }, {
          headers: { Authorization: `Bearer ${token}` },
        });
        swal({
          icon: 'success',
          title: 'Success',
          text: response.data.message,
        });
        window.location.href = '/master-rombel';
      } catch (error) {
        console.error('Error assigning students:', error);
        swal({
          icon: 'error',
          title: 'Error',
          text: 'There was an error assigning students.',
        });
      }
    }
  };

  // Delete Siswa dari Rombel
  const handleDelete = async (siswaId) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(url + "del-siswa-rombel", {
        siswaId: siswaId,
        rombelId: rombel_id
      }, {
        headers: { Authorization: `Bearer ${token}` }
      });
      swal({
        icon: 'success',
        title: 'Deleted',
        text: response.data.message,
      });
      // Refresh the list of students in the rombel
      fetchSiswaRombel();
    } catch (error) {
      console.error('Error deleting student:', error);
      swal({
        icon: 'error',
        title: 'Error',
        text: 'There was an error deleting the student.',
      });
    }
  };


  useEffect(() => {
    fetchSiswa();
    fetchSiswaRombel()
  }, []);


  return (
    <div>
      <div className="container-fluid">
        <div className="page-title">
          <div className="row">
            <div className="col-sm-6 col-12">
              <h2>{content} - {nama_rombel}</h2>
            </div>
            <div className="col-sm-6 col-12">
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><a href="index.html"><i className="fas fa-home"></i></a></li>
                <li className="breadcrumb-item">Dashboard /</li>

                <li className="breadcrumb-item active">{content}</li>
              </ol>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid table-space basic_table">
        <div className="row">
          <div className="col-sm-12">
            <div className="card overflow-hidden">
              <div className="card-header">

                <div className="col-md-6 mt-3 mb-3">

                  <button className="btn btn-primary btn-md" type="button" data-bs-toggle="modal" data-bs-target="#addSiswaRombel">Tambah Siswa</button>

                </div>
              </div>
              <div className="card-body">
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr className="border-bottom-secondary border-top-0">
                        <th scope="col">#</th>
                        <th scope="col">NIS</th>
                        <th scope="col">NISN</th>
                        <th scope="col">Nama Siswa</th>
                        <th scope="col">Aksi</th>
                      </tr>
                    </thead>
                    <tbody>
                      {isLoading ? (
                        <tr>
                          <td colSpan="5" className="text-center">Loading...</td>
                        </tr>
                      ) : siswaRombel.length === 0 ? (
                        <tr>
                          <td colSpan="5" className="text-center">No data available</td>
                        </tr>
                      ) : (
                        siswaRombel.map((siswa, index) => (
                          <tr key={siswa.id}>
                            <td>{index + 1}</td>
                            <td>{siswa.nis}</td>
                            <td>{siswa.nisn}</td>
                            <td>{siswa.nama}</td>
                            <td>
                              <button
                                className='btn btn-danger btn-sm'
                                onClick={() => handleDelete(siswa.id)}
                              >
                                Hapus
                              </button>
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                </div>
              </div>

            </div>
          </div>
        </div>

        <div className="modal fade" id="addSiswaRombel" tabindex="-1" role="dialog" aria-labelledby="addRombel" aria-hidden="true">
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
              <div className="modal-toggle-wrapper ">
                <h3 className="modal-header justify-content-center border-0">Tambahkan Siswa Ke Rombel</h3>
                <div className="modal-body">

                  <form className="row" onSubmit={handleSubmit} >
                    <div className="table-responsive">
                      <input
                        type="text"
                        placeholder="Masukkan Nama Siswa"
                        value={searchTerm}
                        onChange={handleSearch}
                        className="form-control mb-3"
                      />

                      <button className='btn btn-primary' type='submit'>Simpan</button>

                      <table className="table">
                        <thead>
                          <tr className="border-bottom-secondary border-top-0">
                            <th scope="col">
                              <input
                                type="checkbox"
                                checked={selectAll}
                                onChange={handleSelectAll}
                              />
                            </th>
                            <th scope="col">NIS</th>
                            <th scope="col">NISN</th>
                            <th scope="col">Nama Siswa</th>
                            <th scope="col">Aksi</th>
                          </tr>
                        </thead>
                        <tbody>
                          {filteredDataSiswa.map((siswa, index) => (
                            <tr key={siswa.id}>
                              <td>
                                <input
                                  type="checkbox"
                                  checked={selectedSiswa.includes(siswa.id)}
                                  onChange={() => handleCheckboxChange(siswa.id)}
                                />
                              </td>
                              <td>{siswa.nis}</td>
                              <td>{siswa.nisn}</td>
                              <td>{siswa.nama}</td>
                              <td>
                                {/* Add any action buttons or links here */}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>


                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>




      </div>
    </div>
  )
}

export default DetailRombel
