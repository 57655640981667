import React, { useEffect, useState, useContext } from 'react';
import '@fortawesome/fontawesome-free/css/all.min.css';

import { SidebarContext } from './SidebarContext';
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  LogOut,
  reset
} from "../../features/authSlice";


const Menu = () => {

  const { isSidebarOpen } = useContext(SidebarContext);
  const [activeItem, setActiveItem] = useState(null);




  const handleResize = () => {
    var pageWrapper = document.querySelector(".page-wrapper");
    var toggleSidebarButton = document.querySelector(".toggle-sidebar");
    var widthWindow = window.innerWidth;

    if (widthWindow <= 1199) {
      pageWrapper.classList.add("sidebar-open");
      toggleSidebarButton.classList.add("close");
    } else {
      pageWrapper.classList.remove("sidebar-open");
      toggleSidebarButton.classList.remove("close");
    }
  };

  useEffect(() => {
    handleResize(); // Initial check
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isSidebarOpen]); // Add isSidebarOpen to dependency array



  const handleItemClick = (item) => {
    setActiveItem(item === activeItem ? null : item);
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    dispatch(LogOut());
    dispatch(reset());
    localStorage.removeItem("token");
    localStorage.removeItem("name");
    localStorage.removeItem("role");
    localStorage.removeItem("email");
    navigate("/");
  };




  return (


    <div>
      <div
        className={`sidebar ${isSidebarOpen ? 'open' : 'closed'}`}
        id="main-sidebar"
        style={{
          display: 'flex',
          flexDirection: 'column',
          position: 'relative', // Ensure relative positioning
          top: '0', // Adjust as needed
          left: '0', // Adjust as needed
        }}
      >
        <ul className="sidebar-menu" id="simple-bar" style={{ display: 'block' }}>

          <li className="sidebar-list">

            <NavLink to="/dashboard" className={`sidebar-link ${activeItem === 'dashboards' ? 'active' : ''}`} onClick={() => handleItemClick('dashboards')}>
              <i className="fa-solid fa-house" aria-hidden="true"></i>
              <h6>Dashboards</h6>
            </NavLink>

          </li>
          <li className="sidebar-list">
            {/* <i className="fa-solid fa-thumbtack"></i> */}
            <a className={`sidebar-link ${activeItem === 'master' ? 'active' : ''}`} onClick={() => handleItemClick('master')}>
              <i className="fa-solid fa-database" aria-hidden="true"></i>
              <h6>Master Data</h6>
              {/* <span className="badge">3</span><i className="iconly-Arrow-Right-2 icli"></i> */}
            </a>
            <ul className="sidebar-submenu" style={{ display: activeItem === 'master' ? 'block' : 'none' }}>
              <li><NavLink to="/master-tp">Tahun Pelajaran</NavLink></li>
              <li><NavLink to="/master-rombel">Rombel</NavLink></li>
              <li><NavLink to="/master-siswa">Siswa</NavLink></li>
              <li><NavLink to="/master-guru">Guru</NavLink></li>

            </ul>
          </li>

          <li className="sidebar-list">
            {/* <i className="fa-solid fa-thumbtack"></i> */}
            <a className={`sidebar-link ${activeItem === 'setting' ? 'active' : ''}`} onClick={() => handleItemClick('setting')}>
              <i className="fa-solid fa-gears" aria-hidden="true"></i>
              <h6>Setting</h6>
              {/* <span className="badge">3</span><i className="iconly-Arrow-Right-2 icli"></i> */}
            </a>
            <ul className="sidebar-submenu" style={{ display: activeItem === 'setting' ? 'block' : 'none' }}>
              <li><NavLink to="/setting-aplikasi">Setting Aplikasi</NavLink></li>
              <li><NavLink to="/setting-absensi">Setting Jam Kerja</NavLink></li>
            </ul>
          </li>

          <li className="sidebar-list">
            {/* <i className="fa-solid fa-thumbtack"></i> */}
            <a className={`sidebar-link ${activeItem === 'attendance' ? 'active' : ''}`} onClick={() => handleItemClick('attendance')}>
              <i className="fa-solid fa-clipboard-check" aria-hidden="true"></i>
              <h6>Log Absensi</h6>
              {/* <span className="badge">3</span><i className="iconly-Arrow-Right-2 icli"></i> */}
            </a>
            <ul className="sidebar-submenu" style={{ display: activeItem === 'attendance' ? 'block' : 'none' }}>
              <li><NavLink to="/absensi-log">Data Log Kehadiran</NavLink></li>
              <li><NavLink to="/absensi-data">Data Absensi</NavLink></li>
              <li><NavLink to="/absensi-detail">Detail Absensi</NavLink></li>
              <li><NavLink to="/absensi-rekap">Rekap Absensi</NavLink></li>
            </ul>
          </li>


          <li className="sidebar-list">
            {/* <i className="fa-solid fa-thumbtack"></i> */}
            <a className={`sidebar-link ${activeItem === 'userman' ? 'active' : ''}`} onClick={() => handleItemClick('userman')}>
              <i className="fa-solid fa-users" aria-hidden="true"></i>
              <h6>User Manager</h6>
              {/* <span className="badge">3</span><i className="iconly-Arrow-Right-2 icli"></i> */}
            </a>
            <ul className="sidebar-submenu" style={{ display: activeItem === 'userman' ? 'block' : 'none' }}>
              <li><NavLink to="/userman-guru">Guru</NavLink></li>
              <li><NavLink to="/userman-siswa">Siswa</NavLink></li>

            </ul>
          </li>



          <li className="sidebar-list">
            <a onClick={handleLogout} className={`sidebar-link ${activeItem === 'logout' ? 'active' : ''}`} >
              <i className="fa-solid fa-right-from-bracket" aria-hidden="true"></i>
              <h6>Logout</h6>
            </a>
          </li>


        </ul>


      </div>
      {/* <div className="right-arrow" id="right-arrow"><i data-feather="arrow-right"></i></div> */}
    </div>
  );
}

export default Menu;