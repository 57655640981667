import React, { useState, useEffect } from 'react'
import axios from 'axios'
import BottomNav from './BottomNav'
import './bottom.css';



const RekapAbsenUser = ({ url }) => {

  const [data, setData] = useState([])
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);
  const [attendanceData, setAttendanceData] = useState({});
  const [isLoading, setIsLoading] = useState(false)
  const scope = localStorage.getItem('role')

  const handleMonthChange = (event) => {
    setSelectedMonth(parseInt(event.target.value, 10));
  };


  const fetchDataForUser = async (month, year) => {
    setIsLoading(true);
    const token = localStorage.getItem("token");
    if (localStorage.getItem('role') === '2') {
      // Siswa
      try {
        const response = await axios.post(`${url}rekap-sw/batch`, {
          month,
          year,
          email: localStorage.getItem('email')
        }, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setAttendanceData(response.data);
      } catch (error) {
        console.log(error);
      }
    } else if (localStorage.getItem('role') === '3') {
      // Guru
      try {
        const response = await axios.post(`${url}rekap-gr/batch`, {
          month,
          year,
          email: localStorage.getItem('email')
        }, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setAttendanceData(response.data);
      } catch (error) {
        console.log(error);
      }

    }
    setIsLoading(false);
  };


  useEffect(() => {

    fetchDataForUser(selectedMonth, new Date().getFullYear());

  }, [selectedMonth]);






  return (
    <div>
      <div className="container-fluid">
        <div className="page-title">
          <div className="row">
            <div className="col-sm-6 col-12">
              <h2>{data && data.nama_aplikasi}</h2>
            </div>

          </div>
        </div>
      </div>
      <div className="dashboard-container main-content-ku">
        <div className="content">

          <div className="container-fluid">
            <div className="row">
              <div className="col-12">

                <h3>Rekap Data Absensi</h3>

                <div className="col-md-4 mb-3 mt-3">
                  <label htmlFor="monthSelect">Pilih Bulan: </label>
                  <select id="monthSelect" className='form-control' value={selectedMonth} onChange={handleMonthChange}>
                    {['Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember'].map((month, index) => (
                      <option key={index + 1} value={index + 1}>
                        {month}
                      </option>
                    ))}
                  </select>

                </div>

                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr className="border-bottom-secondary border-top-0">
                          <th scope="col" style={{ textAlign: 'center' }}>H</th>
                          <th scope="col" style={{ textAlign: 'center' }}>TP</th>
                          <th scope="col" style={{ textAlign: 'center' }}>Tl</th>
                          <th scope="col" style={{ textAlign: 'center' }}>PC</th>
                          {scope === '3' && (
                            <th scope="col" style={{ textAlign: 'center' }}>Lbr</th>
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        {attendanceData && attendanceData.length > 0 ? (
                          attendanceData.map((entry, index) => (
                            <tr key={index}>
                              <td style={{ textAlign: 'center' }}>{entry.masuk}</td>
                              <td style={{ textAlign: 'center' }}>{entry.tepat}</td>
                              <td style={{ textAlign: 'center' }}>{entry.terlambat}</td>
                              <td style={{ textAlign: 'center' }}>{entry.pulangCepat}</td>
                              {scope === '3' && (
                                <td style={{ textAlign: 'center' }} >{entry.lembur}</td>
                              )}
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={scope === '3' ? 5 : 4}>No data available</td>
                          </tr>
                        )}

                      </tbody>
                    </table>
                  </div>

                  <p className='mt-3'>Keterangan : </p>
                  <ul>
                    <li>H : Hadir</li>
                    <li>TP : Tepat Waktu</li>
                    <li>Tl : Terlambat</li>
                    <li>PC : Pulang Cepat / lebih awal</li>
                    <li>Lbr : Lembur</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Menu Mobile */}
      <BottomNav />
    </div>
  )
}

export default RekapAbsenUser
