import React, { useState, useEffect } from 'react'
import swal from 'sweetalert';
import axios from 'axios';
import { Modal, Button } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';



const MasterRombel = ({ url, content }) => {

  const [selectedLevel, setSelectedLevel] = useState('')
  const [dataRombel, setDataRombel] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [rombel, setRombel] = useState('')
  const [jumlahSiswa, setJumlahSiswa] = useState({});

  const fetchRombelAll = async () => {
    setIsLoading(true)
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(url + "rombel", {
        headers: { Authorization: `Bearer ${token}` },
      });
      setDataRombel(response.data.data);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false)
  };

  const fetchRombelByLevel = async () => {
    setIsLoading(true)
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(url + "rombelbylevel", {
        level: selectedLevel
      }, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setDataRombel(response.data.data);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false)
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(url + "add-rombel", {
        level: selectedLevel,
        nama_rombel: rombel
      }, {
        headers: { Authorization: `Bearer ${token}` },
      });

      fetchRombelByLevel();

      swal({
        title: "Success!",
        text: "Rombel has been added successfully.",
        icon: "success",
        button: "OK",
      }).then(() => {
        // Close the modal
        const modalElement = document.getElementById('addRombel');
        if (modalElement) {
          const modalInstance = window.bootstrap.Modal.getOrCreateInstance(modalElement);
          setRombel('');
          modalInstance.hide();
        }
      });

    } catch (error) {
      console.log(error)
      swal({
        title: "Oops!",
        text: error.response.data.message,
        icon: "error",
        button: "OK",
      }).then(() => {
        // Close the modal
        const modalElement = document.getElementById('addRombel');
        if (modalElement) {
          const modalInstance = window.bootstrap.Modal.getOrCreateInstance(modalElement);
          setRombel('');
          modalInstance.hide();
        }
      });
    }
  };

  // Get Jumlah Siswa Rombel
  const fetchJumlahSiswa = async (rombelId) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(url + `jumlahsiswa/${rombelId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setJumlahSiswa(prevState => ({
        ...prevState,
        [rombelId]: response.data.jumlahSiswa
      }));
    } catch (error) {
      console.log(error);
    }
  };


  useEffect(() => {
    fetchRombelAll();
  }, []);

  useEffect(() => {
    fetchRombelByLevel();
  }, [selectedLevel]);

  useEffect(() => {
    dataRombel.forEach(rombel => {
      fetchJumlahSiswa(rombel.id);
    });
  }, [dataRombel]);



  return (
    <div>
      <div className="container-fluid">
        <div className="page-title">
          <div className="row">
            <div className="col-sm-6 col-12">
              <h2>{content}</h2>
            </div>
            <div className="col-sm-6 col-12">
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><a href="index.html"><i className="fas fa-home"></i></a></li>
                <li className="breadcrumb-item">Dashboard /</li>

                <li className="breadcrumb-item active">{content}</li>
              </ol>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid table-space basic_table">
        <div className="row">
          <div className="col-sm-12">
            <div className="card overflow-hidden">
              <div className="card-header">
                <div className="col-md-4">
                  <label>Pilih Level / Kelas terlebih dahulu </label>
                  <select className='form-control' value={selectedLevel} onChange={(e) => setSelectedLevel(e.target.value)}>
                    <option value="">Pilih Level</option>
                    <option value="10">Kelas 10</option>
                    <option value="11">Kelas 11</option>
                    <option value="12">Kelas 12</option>
                  </select>
                </div>
                <div className="col-md-6 mt-3 mb-3">
                  {selectedLevel && (
                    <button className="btn btn-primary btn-md" type="button" data-bs-toggle="modal" data-bs-target="#addRombel">Tambah Data</button>
                  )}
                </div>
              </div>
              <div className="card-body">
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr className="border-bottom-secondary border-top-0">
                        <th scope="col">#</th>
                        <th scope="col">Level / Kelas</th>
                        <th scope="col">Nama Rombel</th>
                        <th scope="col">Jumlah Siswa</th>
                        <th scope="col">Detail</th>
                      </tr>
                    </thead>
                    <tbody>
                      {isLoading ? (
                        <tr>
                          <td colSpan="4" className="text-center"><div className="spinner-border" role="status">
                            <span className="visually-hidden">Loading...</span>
                          </div></td>
                        </tr>
                      ) : dataRombel.length === 0 ? (
                        <tr>
                          <td colSpan="4" className="text-center">No Data Available</td>
                        </tr>
                      ) : (
                        dataRombel.map((rombel, index) => (
                          <tr key={rombel.id}>
                            <th scope="row">{index + 1}</th>
                            <td>{rombel.level}</td>
                            <td>{rombel.nama_rombel}</td>
                            <td>{jumlahSiswa[rombel.id] !== undefined ? jumlahSiswa[rombel.id] : 'Loading...'}</td>
                            <td>
                              <NavLink to={`/detail-rombel/${rombel.nama_rombel}/${rombel.id}`} className="btn btn-info btn-sm">Detail
                              </NavLink>
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                </div>
              </div>

            </div>
          </div>
        </div>


        <div className="modal fade" id="addRombel" tabindex="-1" role="dialog" aria-labelledby="addRombel" aria-hidden="true">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-toggle-wrapper social-profile text-start dark-sign-up">
                <h3 className="modal-header justify-content-center border-0">Tambahkan Rombel</h3>
                <div className="modal-body">

                  <form className="row" onSubmit={handleSubmit}>
                    <div className="col-md-12">
                      <label className="form-label" htmlFor="level">Level / Kelas</label>
                      <input
                        className="form-control"
                        id="level"
                        type="text"
                        value={selectedLevel}
                        readOnly />
                    </div>
                    <div className="col-md-12">
                      <label className="form-label" htmlFor="nama_rombel">Nama Rombel</label>
                      <input
                        className="form-control"
                        id="nama_rombel"
                        type="text"
                        placeholder="Masukkan Nama Rombel"
                        value={rombel} onChange={(e) => setRombel(e.target.value)}
                        required />
                    </div>

                    <div className="col-md-12 mt-3">
                      <button className="btn btn-primary" type="submit">Simpan</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>


      </div>



    </div>
  )
}

export default MasterRombel
