import React, { useState, useEffect } from 'react'
import axios from 'axios'
import Footer from '../components/templates/Footer'
import Header from '../components/templates/Header'
import Menu from '../components/templates/Menu'
import { SidebarProvider } from '../components/templates/SidebarContext'

const Layouts = ({ children, url }) => {


  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchUser = async () => {
    setIsLoading(true);
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(url + "me", {
        headers: { Authorization: `Bearer ${token}` },
      });
      setUser(response.data.user);
    } catch (error) {
      if (error.response) {
        const message = error.response.data.message;
        // console.log(message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchUser();
  }, []);

  return (
    <>

      <div className="tap-top"><i className="iconly-Arrow-Up icli"></i></div>



      <div className="page-wrapper compact-wrapper" id="pageWrapper">
        <SidebarProvider>
          <Header user={user} url={url} />

          <div className="page-body-wrapper">
            <aside className="page-sidebar">
              <Menu user={user} url={url} />
            </aside>
            <div className="page-body">
              {children}
            </div>
            <Footer url={url} />
          </div>
        </SidebarProvider>
      </div>
    </>
  )
}

export default Layouts
