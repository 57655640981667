import React, { useState, useEffect } from 'react'
import axios from 'axios';
import swal from 'sweetalert';
import $ from 'jquery';
import 'datatables.net';
import 'datatables.net-dt/css/dataTables.dataTables.css';
import { Modal } from 'bootstrap';



const MasterGuru = ({ url, content }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [loadingUpload, setLoadingUpload] = useState(false)
  const [guru, setGuru] = useState([])
  const [file, setFile] = useState(null);

  const [selectedGuru, setSelectedGuru] = useState(null);
  const [editFormData, setEditFormData] = useState({
    id: '',
    pegid: '',
    nama: '',
    rfid: ''
  });

  const handleEditClick = (guru) => {
    setSelectedGuru(guru);
  };


  const fetchGuru = async () => {
    setIsLoading(true)
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(url + "guru", {
        headers: { Authorization: `Bearer ${token}` },
      });
      setGuru(response.data.data);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false)
  };


  // Download Template
  const handleDownloadTemplate = async () => {
    const token = localStorage.getItem("token");
    setIsLoading(true);
    try {
      const response = await axios({
        url: url + "template-guru",
        method: "get",
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const contentDisposition = response.headers["content-disposition"];
      const fileName = contentDisposition
        ? contentDisposition.split("filename=")[1]
        : "Template Guru" + ".xlsx";
      const validExtensions = [".xlsx", ".xls"];
      const fileExtension = fileName.slice(fileName.lastIndexOf("."));

      if (!validExtensions.includes(fileExtension)) {
        throw new Error("Invalid file extension");
      }

      const url_ = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url_;
      link.setAttribute("download", fileName);
      document.body.appendChild(link); // Ensure the link is appended to the body
      link.click();
      document.body.removeChild(link); // Remove the link after clicking
      window.URL.revokeObjectURL(url_); // Clean up the URL object
    } catch (error) {
      console.error("Error downloading template:", error);
    } finally {
      setIsLoading(false);
    }
  };

  // Upload Template
  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  // Proses Upload Template
  const uploadTemplate = async (e) => {
    e.preventDefault();
    if (!file) {
      swal({
        title: "Oops!",
        text: "Pilih File Terlebih Dahulu",
        icon: "error",
        button: "OK",
      })
      return;
    }

    const validExtensions = [".xlsx", ".xls"];
    const fileExtension = file.name.slice(file.name.lastIndexOf("."));
    const maxSize = 5 * 1024 * 1024; // 5MB

    if (!validExtensions.includes(fileExtension)) {
      swal({
        title: "Oops!",
        text: "Invalid file type. Please upload an Excel file.",
        icon: "error",
        button: "OK",
      })
      return;
    }

    if (file.size > maxSize) {
      swal({
        title: "Oops!",
        text: "File size exceeds the limit of 5MB.",
        icon: "error",
        button: "OK",
      })
      return;
    }

    setLoadingUpload(true);

    const token = localStorage.getItem("token");
    const formData = new FormData();
    formData.append("file", file);

    try {
      await axios.post(url + "import-guru", formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });
      await fetchGuru();

      swal({
        title: "Success!",
        text: "Upload data successfully.",
        icon: "success",
        button: "OK",
      }).then(() => {
        // Close the modal
        const modalElement = document.getElementById('importSiswa');
        if (modalElement) {
          const modalInstance = window.bootstrap.Modal.getOrCreateInstance(modalElement);
          setFile(null);
          modalInstance.hide();
        }
      });

    } catch (error) {
      // console.error("Error uploading file:", error);

      swal({
        title: "Oops!",
        text: error.response.data.error,
        icon: "error",
        button: "OK",
      }).then(() => {
        // Close the modal
        const modalElement = document.getElementById('importSiswa');
        if (modalElement) {
          const modalInstance = window.bootstrap.Modal.getOrCreateInstance(modalElement);
          setFile(null);
          modalInstance.hide();
        }
      });

    } finally {
      setLoadingUpload(false);

    }
  };

  useEffect(() => {
    fetchGuru();
  }, []);




  // Use useEffect to show the modal after selectedGuru is updated
  useEffect(() => {
    if (selectedGuru) {
      setEditFormData({
        id: selectedGuru.id,
        pegid: selectedGuru.pegid,
        nama: selectedGuru.nama,
        rfid: selectedGuru.rfid || '' // Set to empty string if rfid is empty
      });

      const modalElement = document.getElementById(`editGuru-${selectedGuru.id}`);
      if (modalElement) {
        const modalInstance = Modal.getOrCreateInstance(modalElement);
        modalInstance.show();
      }
      // Focus the RFID input after the modal is shown
      modalElement.addEventListener('shown.bs.modal', () => {
        const rfidInput = modalElement.querySelector('#rfid');
        if (rfidInput) {
          rfidInput.focus();
        }
      });
    }
  }, [selectedGuru]);

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setEditFormData((prevData) => ({
      ...prevData,
      [id]: value
    }));
  };

  // Simpan Edit
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    // Logic to save the changes
    try {
      const token = localStorage.getItem("token");

      const response = await axios.post(url + "upd-guru", {
        id: editFormData.id,
        pegid: editFormData.pegid,
        nama: editFormData.nama,
        rfid: editFormData.rfid,
      }, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response.data.status === true) {
        swal({
          title: "Success!",
          text: "Siswa has been updated successfully.",
          icon: "success",
          button: "OK",
        }).then(() => {
          // Close the modal after the alert is acknowledged
          const modalElement = document.getElementById(`editGuru-${selectedGuru.id}`);
          if (modalElement) {
            const modalInstance = Modal.getOrCreateInstance(modalElement);
            modalInstance.hide();
          }
        });
      }
      window.location.reload();

    } catch (error) {
      console.log(error)
      swal({
        title: "Oops!",
        text: error.response.data.message,
        icon: "error",
        button: "OK",
      }).then(() => {
        // Close the modal after the alert is acknowledged
        const modalElement = document.getElementById(`editGuru-${selectedGuru.id}`);
        if (modalElement) {
          const modalInstance = Modal.getOrCreateInstance(modalElement);
          modalInstance.hide();
        }
      });
    }


    // console.log('Saving changes:', editFormData);
    // Close the modal after saving
    const modalElement = document.getElementById(`editGuru-${selectedGuru.id}`);
    if (modalElement) {
      const modalInstance = Modal.getOrCreateInstance(modalElement);
      modalInstance.hide();
    }
  };


  const handleAktifkanGuru = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(url + "aktifkan-guru", {}, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response.data.status === true) {
        swal({
          title: "Success!",
          text: "Akun guru telah diaktifkan.",
          icon: "success",
          button: "OK",
        });
        window.location.reload();
      }
    } catch (error) {
      console.error("Error activating student accounts:", error);
      swal({
        title: "Oops!",
        text: error.response.data.message || "Terjadi kesalahan saat mengaktifkan akun guru.",
        icon: "error",
        button: "OK",
      });
    }
  };



  return (
    <div>
      <div className="container-fluid">
        <div className="page-title">
          <div className="row">
            <div className="col-sm-6 col-12">
              <h2>{content}</h2>
            </div>
            <div className="col-sm-6 col-12">
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><a href="index.html"><i className="fas fa-home"></i></a></li>
                <li className="breadcrumb-item">Dashboard /</li>

                <li className="breadcrumb-item active">{content}</li>
              </ol>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid table-space basic_table">
        <div className="row">

          <div className="col-sm-12">
            <div className="card overflow-hidden">
              <div className="card-header">

                <div className="row">
                  <div className="col-md-4 mt-3 mb-3 d-flex justify-content-start">
                    <button className="btn btn-primary btn-md me-2" type="button" data-bs-toggle="modal" data-bs-target="#importGuru">Import</button>

                    <button className="btn btn-warning btn-md" type="button" onClick={handleAktifkanGuru}>Aktifkan Akun Guru</button>

                  </div>
                </div>



              </div>
              <div className="card-body">
                <div className="table-responsive">
                  {isLoading ? (
                    <div className="text-center">
                      <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  ) : (
                    <table className="table">
                      <thead>
                        <tr className="border-bottom-secondary border-top-0">
                          <th scope="col">#</th>
                          <th scope="col" style={{ textAlign: 'left' }}>PEGID / NIK</th>
                          <th scope="col" style={{ textAlign: 'left' }}>NAMA</th>
                          <th scope="col" style={{ textAlign: 'left' }}>RFID</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {guru.length > 0 ? (
                          guru.map((item, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td style={{ textAlign: 'left' }}>{item.pegid || item.nik}</td>
                              <td style={{ textAlign: 'left' }}>{item.nama}</td>
                              <td style={{ textAlign: 'left' }}>{item.rfid}</td>
                              <td>
                                <button
                                  className='btn btn-primary btn-sm'
                                  onClick={() => handleEditClick(item)} // Use the function to show the modal
                                >
                                  Edit
                                </button>
                              </td>

                              {/* Modal Edit */}

                              {selectedGuru && item && selectedGuru.id === item.id && (
                                <div
                                  className="modal fade"
                                  id={`editGuru-${item.id}`}
                                  tabIndex="-1"
                                  role="dialog"
                                  aria-labelledby="editGuru"
                                  aria-hidden="true"
                                  key={selectedGuru.id}
                                >
                                  <div className="modal-dialog" role="document">
                                    <div className="modal-content">
                                      <div className="modal-header">
                                        <h5 className="modal-title">Edit Guru</h5>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                      </div>
                                      <div className="modal-body">
                                        <form onSubmit={handleFormSubmit}>
                                          <div className="mb-3">
                                            <label htmlFor="pegid" className="form-label">PEGID / NIK</label>
                                            <input type="text" className="form-control" id="pegid" value={editFormData.pegid} onChange={handleInputChange} />
                                          </div>

                                          <div className="mb-3">
                                            <label htmlFor="nama" className="form-label">Nama</label>
                                            <input type="text" className="form-control" id="nama" value={editFormData.nama} onChange={handleInputChange} />
                                          </div>
                                          <div className="mb-3">
                                            <label htmlFor="rfid" className="form-label">RFID</label>
                                            <input type="text" className="form-control" id="rfid" value={editFormData.rfid} onChange={handleInputChange} autoFocus />
                                          </div>
                                          <button type="submit" className="btn btn-primary">Save changes</button>
                                        </form>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}


                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="5" className="text-center">No data available</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  )}
                </div>
              </div>

            </div>
          </div>
        </div>


        <div className="modal fade" id="importGuru" tabindex="-1" role="dialog" aria-labelledby="importGuru" aria-hidden="true">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-toggle-wrapper social-profile text-start dark-sign-up">
                <h3 className="modal-header justify-content-center border-0">Import Data Guru</h3>
                <div className="modal-body">

                  <button
                    className="btn btn-warning sm-btn"
                    onClick={handleDownloadTemplate}
                    disabled={isLoading}
                  >
                    {isLoading ? "Loading..." : "Download Template"}
                  </button>

                  <form className="row" >
                    <div className="col-md-12 mt-3">

                      <input
                        type="file"
                        onChange={handleFileChange}
                        className="form-control mt-3" required
                      />
                    </div>


                    <div className="col-md-12 mt-3">
                      <button className="btn btn-primary" type="submit" onClick={uploadTemplate}>Upload</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>


      </div>


    </div>
  )
}

export default MasterGuru
