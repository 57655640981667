import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";


const initialState = {
  user: null,
  isLoading: false,
  isError: false,
  isSuccess: false,
  message: ""
};


// const apiUrl = "http://127.0.0.1:8000/api/";
const apiUrl = "https://api-absen.mamuda.my.id/api/";

export const LoginUser = createAsyncThunk(
  "auth/LoginUser",
  async (user, thunkAPI) => {
    try {
      const response = await axios.post(
        apiUrl + "login",
        {
          email: user.email,
          password: user.password,
        },
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );

      // console.log("token", response.data.token)
      localStorage.setItem("token", response.data.token);
      localStorage.setItem("role", response.data.user.role_id);
      localStorage.setItem("name", response.data.user.name);
      localStorage.setItem("email", response.data.user.email);
      return response.data;
    } catch (error) {
      if (error.response) {
        const message = error.response.data.message;
        // console.log(message);
        return thunkAPI.rejectWithValue(message);
      }
    }
  }
);

export const getMe = createAsyncThunk("user/getMe", async (_, thunkAPI) => {
  const token = localStorage.getItem("token");

  try {
    const response = await axios.get(apiUrl + "me", {
      headers: { Authorization: `Bearer ${token}` },
    });
    localStorage.setItem("kdagm", response.data.user.agm_desk);
    return response.data;
  } catch (error) {
    if (error.response) {
      const message = error.response.data.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
});



export const LogOut = createAsyncThunk("user/LogOut", async () => {
  const token = localStorage.getItem("token");
  const response = await axios.post(apiUrl + "logout", {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
});

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    reset: (state) => initialState,
  },

  extraReducers: (builder) => {
    builder.addCase(LoginUser.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(LoginUser.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.user = action.payload;
    });

    builder.addCase(LoginUser.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.message = action.payload;
    });

    // Get Me
    builder.addCase(getMe.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(getMe.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.user = action.payload;
    });

    builder.addCase(getMe.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.message = action.payload;
    });
  },
});

export const { reset } = authSlice.actions;
export default authSlice.reducer;
