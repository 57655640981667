import React, { useState, useEffect } from 'react'
import swal from 'sweetalert';
import axios from 'axios';
import { Modal, Button } from 'react-bootstrap';


const MasterTp = ({ content, url }) => {

  const [dataTp, setDataTp] = useState([]);
  const [dataSmt, setDataSmt] = useState([]);
  const [tahunPelajaran, setTahunPelajaran] = useState('');
  const [isActive, setIsActive] = useState(0);
  const [isLoading, setIsLoading] = useState(false)

  const fetchTp = async () => {
    setIsLoading(true)
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(url + "tp", {
        headers: { Authorization: `Bearer ${token}` },
      });
      setDataTp(response.data.data);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false)
  };

  const fetchSmt = async () => {
    setIsLoading(true)
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(url + "smt", {
        headers: { Authorization: `Bearer ${token}` },
      });
      setDataSmt(response.data.data);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false)
  };



  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(url + "add-tp", {
        tp: tahunPelajaran,
        is_active: isActive
      }, {
        headers: { Authorization: `Bearer ${token}` },
      });

      fetchTp();

      swal({
        title: "Success!",
        text: "Tahun Pelajaran has been added successfully.",
        icon: "success",
        button: "OK",
      }).then(() => {
        // Close the modal
        const modalElement = document.getElementById('addTp');
        if (modalElement) {
          const modalInstance = window.bootstrap.Modal.getOrCreateInstance(modalElement);
          setTahunPelajaran('');
          modalInstance.hide();
        }
      });

    } catch (error) {
      swal({
        title: "Oops!",
        text: error.response.data.message,
        icon: "error",
        button: "OK",
      }).then(() => {
        // Close the modal
        const modalElement = document.getElementById('addTp');
        if (modalElement) {
          const modalInstance = window.bootstrap.Modal.getOrCreateInstance(modalElement);
          setTahunPelajaran('');
          modalInstance.hide();
        }
      });
    }
  };

  const handleAktifkanTp = async (id, currentStatus) => {
    const token = localStorage.getItem("token");
    try {

      const response = await axios.put(url + "tp/" + id, {}, {
        headers: { Authorization: `Bearer ${token}` },
      });
      fetchTp(); // Refresh the data after toggling
      swal({
        title: "Success!",
        text: "Status has been updated successfully.",
        icon: "success",
        button: "OK",
      });
    } catch (error) {
      swal({
        title: "Oops!",
        text: error.response.data.message,
        icon: "error",
        button: "OK",
      });
    }
  };

  const handleAktifkanSmt = async (id, currentStatus) => {
    const token = localStorage.getItem("token");
    try {

      const response = await axios.put(url + "smt/" + id, {}, {
        headers: { Authorization: `Bearer ${token}` },
      });
      fetchSmt(); // Refresh the data after toggling
      swal({
        title: "Success!",
        text: "Status has been updated successfully.",
        icon: "success",
        button: "OK",
      });
    } catch (error) {
      swal({
        title: "Oops!",
        text: error.response.data.message,
        icon: "error",
        button: "OK",
      });
    }
  };

  useEffect(() => {
    fetchTp();
    fetchSmt();

  }, []);


  return (
    <div>

      <div className="container-fluid">
        <div className="page-title">
          <div className="row">
            <div className="col-sm-6 col-12">
              <h2>{content}</h2>

            </div>
            <div className="col-sm-6 col-12">
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><a href="index.html"><i className="fas fa-home"></i></a></li>
                <li className="breadcrumb-item">Dashboard /</li>

                <li className="breadcrumb-item active">{content}</li>
              </ol>
            </div>
          </div>
        </div>
      </div>


      <div className="container-fluid table-space basic_table">
        <div className="row">
          {/* Tahun Pelajaran */}
          <div className="col-sm-6">
            <div className="card overflow-hidden">
              <div className="card-header card-no-border">
                <h3>Tahun Pelajaran</h3>
              </div>
              <div className="col-md-6 mt-3 mb-3 px-3">
                <button className="btn btn-primary btn-md" type="button" data-bs-toggle="modal" data-bs-target="#addTp">Tambah Data</button>
              </div>
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr className="border-bottom-secondary border-top-0">
                      <th scope="col">#</th>
                      <th scope="col">Tahun Pelajaran</th>
                      <th scope="col">Status</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {isLoading ? (
                      <tr>
                        <td colSpan="4" className="text-center">
                          <div className="spinner-border" role="status">
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        </td>
                      </tr>
                    ) : dataTp.length === 0 ? (
                      <tr>
                        <td colSpan="4" className="text-center">No data available</td>
                      </tr>
                    ) : (
                      dataTp.map((item, index) => (
                        <tr key={index} className="border-bottom-success">
                          <th scope="row">{index + 1}</th>
                          <th scope="row">{item.tp}</th>
                          <th scope="row">
                            <span className={`badge ${item.is_active === '1' ? 'badge-success' : 'badge-danger'}`}>
                              {item.is_active === '1' ? 'Active' : 'Nonaktif'}
                            </span>
                          </th>
                          <th>
                            <button className={`btn btn-${item.is_active === '0' ? 'success' : ''} btn-sm`} onClick={() => handleAktifkanTp(item.id, item.is_active)}>
                              {item.is_active === '0' ? 'Aktifkan' : ''}
                            </button>
                          </th>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          {/* Semester */}
          <div className="col-sm-6">
            <div className="card overflow-hidden">
              <div className="card-header card-no-border">
                <h3>Semester</h3>
                {/* <p className="desc mb-0 mt-1"><span>use a class</span><code>table-*.border-bottom-*</code><span>class for Border bottom color</span></p> */}
              </div>
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr className="border-bottom-secondary border-top-0">
                      <th scope="col">#</th>
                      <th scope="col">Semester</th>
                      <th scope="col">Status</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {isLoading ? (
                      <tr>
                        <td colSpan="4" className="text-center">
                          <div className="spinner-border" role="status">
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        </td>
                      </tr>
                    ) : dataSmt.length === 0 ? (
                      <tr>
                        <td colSpan="4" className="text-center">No data available</td>
                      </tr>
                    ) : (
                      dataSmt.map((item, index) => (
                        <tr key={index} className="border-bottom-success">
                          <th scope="row">{index + 1}</th>
                          <th scope="row">{item.semester}</th>
                          <th scope="row">
                            <span className={`badge ${item.is_active === '1' ? 'badge-success' : 'badge-danger'}`}>
                              {item.is_active === '1' ? 'Active' : 'Nonaktif'}
                            </span>
                          </th>
                          <th>
                            <button className={`btn btn-${item.is_active === '0' ? 'success' : ''} btn-sm`} onClick={() => handleAktifkanSmt(item.id, item.is_active)}>
                              {item.is_active === '0' ? 'Aktifkan' : ''}
                            </button>
                          </th>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* modal add tp */}
      <div className="modal fade" id="addTp" tabindex="-1" role="dialog" aria-labelledby="addTp" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-toggle-wrapper social-profile text-start dark-sign-up">
              <h3 className="modal-header justify-content-center border-0">Add Tahun Pelajaran</h3>
              <div className="modal-body">

                <form className="row" onSubmit={handleSubmit}>
                  <div className="col-md-12">
                    <label className="form-label" htmlFor="tp">Tahun Pelajaran</label>
                    <input
                      className="form-control"
                      id="tp"
                      type="text"
                      placeholder="Masukkan Tahun Pelajaran Contoh : 2024/2025"
                      value={tahunPelajaran}
                      onChange={(e) => setTahunPelajaran(e.target.value)}
                      required />
                  </div>

                  <div className="col-md-12 mt-3">
                    <div className="form-check mb-3">
                      <input
                        className="form-check-input"
                        id="is_active"
                        type="checkbox"
                        checked={isActive}
                        onChange={(e) => setIsActive(e.target.checked)}
                      />
                      <label className="form-check-label d-block mb-0" htmlFor="is_active">Is Active</label>
                    </div>
                    <button className="btn btn-primary" type="submit">Simpan</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}

export default MasterTp
