import React, { useState, useEffect, useRef } from 'react'
import axios from 'axios';
import swal from 'sweetalert';
import { NavLink } from 'react-router-dom';


const Landing = ({ url }) => {

  const [currentTime, setCurrentTime] = useState(new Date().toLocaleTimeString('en-US', { hour12: false, hour: '2-digit', minute: '2-digit', second: '2-digit' }));

  const [currentDate, setCurrentDate] = useState(new Intl.DateTimeFormat('en-CA', { timeZone: 'Asia/Jakarta' }).format(new Date()));

  const [log, setLog] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const chatContainerRef = useRef(null);

  const [data, setData] = useState([])


  const fetchSistem = async () => {

    try {
      const response = await axios.get(url + "aplikasi");
      setData(response.data.data)
    } catch (error) {
      console.log(error);
    }

  };




  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = 0; // Scroll to top
    }
  }, [log]);

  useEffect(() => {
    fetchSistem()
  }, []);


  const fetchData = async () => {
    setIsLoading(true)
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(url + "log", {
        headers: { Authorization: `Bearer ${token}` },
      });
      setLog(response.data.data);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false)
  };





  useEffect(() => {
    const rfidInput = document.querySelector('#tagrfid');
    if (rfidInput) {
      rfidInput.focus();
    }
    fetchData();

    const handleOverlayClick = (event) => {
      event.stopPropagation();
      event.preventDefault();
      if (rfidInput) {
        rfidInput.focus();
      }
    };

    const overlay = document.querySelector('#overlay');
    if (overlay) {
      overlay.addEventListener('click', handleOverlayClick);
    }

    return () => {
      if (overlay) {
        overlay.removeEventListener('click', handleOverlayClick);
      }
    };







  }, []);


  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date().toLocaleTimeString('en-US', { hour12: false, hour: '2-digit', minute: '2-digit', second: '2-digit' }));
    }, 1000);

    return () => clearInterval(timer);
  }, []);


  const formatDate = (date) => {
    const [day, month, year] = date.split('/');
    return `${year}-${month}-${day}`;
  };
  // Fungsi untuk mengirim data ke server
  const sendDataToServer = async (rfidData) => {

    console.log('Mengirim data:', rfidData); // Log data yang dikirim
    setIsLoading(true)
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(url + "add-log", {
        rfid: rfidData,
        date: currentDate,
        time: currentTime
      }, {
        headers: { Authorization: `Bearer ${token}` },
      });

      fetchData();
      document.querySelector('#tagrfid').value = '';    // kosongkan

      swal({
        title: "Success!",
        text: "Data kehadiran berhasil disimpan",
        icon: "success",
        timer: 1000,
      }).then(() => {
        const rfidInput = document.querySelector('#tagrfid');
        if (rfidInput) {
          rfidInput.focus();
        }
      });
    } catch (error) {
      console.log(error);
      document.querySelector('#tagrfid').value = '';    // kosongkan
      const rfidInput = document.querySelector('#tagrfid');
      if (rfidInput) {
        rfidInput.focus();
      }
      swal({
        title: "Oops!",
        text: error.response.data.message,
        icon: "error",
        timer: 1000,
      }).then(() => {
        const rfidInput = document.querySelector('#tagrfid');
        if (rfidInput) {
          rfidInput.focus();
        }
      });
    }
    setIsLoading(false)
  };
  // Fungsi untuk menangani tap RFID
  const handleRFIDTap = (event) => {
    const rfidData = event.target.value; // Ganti dengan cara mendapatkan data RFID yang sesuai
    const expectedRFIDLength = 10; // Replace with the actual expected length of the RFID data

    if (rfidData.length === expectedRFIDLength) {
      console.log('RFID Tap detected, data:', rfidData); // Log when the data is complete
      sendDataToServer(rfidData);
    } else {
      console.log('RFID data is incomplete:', rfidData); // Log incomplete data
    }
  };


  // Assuming 'log' is the state that contains your fetched data
  const sortedLog = log.map((entry, index) => ({
    ...entry,
    key: index // Add a unique key if needed
  }));

  //  Combine 'masuk' and 'pulang' entries into a single array
  const combinedLog = sortedLog.flatMap((entry, index) => [
    entry.des_masuk === 'Hadir' && {
      type: 'masuk',
      name: entry.name,
      time: entry.masuk,
      date: entry.tanggal,
      description: entry.des_masuk,
      value: entry.val_masuk,
      key: `masuk-${index}`
    },
    entry.des_pulang === 'Pulang' && {
      type: 'pulang',
      name: entry.name,
      time: entry.pulang,
      date: entry.tanggal,
      description: entry.des_pulang,
      value: entry.val_pulang,
      key: `pulang-${index}`
    }
  ].filter(Boolean));

  // Sort the combined entries by date and time
  const sortedCombinedLog = combinedLog.sort((a, b) => {
    const dateTimeA = new Date(`${a.date} ${a.time}`);
    const dateTimeB = new Date(`${b.date} ${b.time}`);
    return dateTimeB - dateTimeA; // Sort in descending order
  });



  return (
    <div>
      <div className="landing-page">


        <header className="landing-header">
          <div className="container-fluid fluid-space">
            <div className="row">
              <div className="col-12">
                <nav className="navbar navbar-light p-0" id="navbar-example2">
                  <a className="navbar-brand" href="#">
                    <img className="img-fluid" src={data.logo_aplikasi} alt="Logo" />
                  </a>

                  <div className="buy-block">

                    <NavLink to="/login" className="btn-header btn btn-primary" rel="noopener noreferrer">Login</NavLink>
                    <div className="toggle-menu"><i className="fa-solid fa-bars"></i></div>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </header>

        <section className="landing-home" id="home">
          <div className="container">
            <div className="landing-center landing-center-responsive title-padding">
              <div className="main-content-home">
                <div className="row">
                  <div className="col-md-8">
                    <div className="title-content">
                      <img src={data && data.logo} width={50} />

                      <p>Selamat Datang</p>
                      <h1><span className="animation-line"> {data && data.nama_aplikasi} {data && data.nama_sekolah}</span> </h1>
                      <p>Sistem Absensi Elektronik </p>
                      <h1><span> Tanggal : {currentDate} <br /> <br />Jam :{currentTime} </span> </h1>
                    </div>

                    <br />
                    <div className='py-2'>
                      <h4 style={{ color: 'white' }}>Silahkan tempel kartu pada mesin <br /> yang telah disediakan!</h4>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="container-fluid mt-3">
                      <div className="card">

                        <div class="card right-sidebar-chat">
                          <div class="right-sidebar-title">
                            <h3>Log Kehadiran</h3>
                            <input
                              type="password" id="tagrfid"
                              onChange={handleRFIDTap}
                              placeholder="Log Kehadiran"
                              className='form-control-sm'
                              style={{
                                position: 'absolute',
                                width: '1px',
                                height: '1px',
                                margin: '-1px',
                                padding: '0',
                                border: '0',
                                overflow: 'hidden',
                                clip: 'rect(0, 0, 0, 0)',
                                whiteSpace: 'nowrap'
                              }}
                            />
                          </div>
                          <div class="right-sidebar-Chats">
                            <div class="msger">
                              <div className="msger-chat" ref={chatContainerRef}>

                                {isLoading ? (
                                  <div className="spinner-border" role="status">
                                    <span className="sr-only">Loading...</span>
                                  </div>
                                ) : log.length === 0 ? (
                                  <p>No data available</p>
                                ) : (
                                  sortedCombinedLog.map((entry) => (
                                    <div className={`msg ${entry.type === 'masuk' ? 'left-msg' : 'right-msg'}`} key={entry.key}>
                                      <div className="msg-img"></div>
                                      <div className="msg-bubble">
                                        <div className="msg-info">
                                          <div className="msg-info-name">{entry.name}</div>
                                          <div className="msg-info-time">{entry.date} {entry.time}</div>
                                        </div>
                                        <div className="msg-text">{entry.description} - {entry.value}</div>
                                      </div>
                                    </div>
                                  ))
                                )}

                              </div>

                            </div>
                          </div>


                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>

            </div>
          </div>

        </section>

      </div>
    </div>
  )
}

export default Landing
