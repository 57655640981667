import React, { useState, useEffect } from 'react'
import swal from 'sweetalert';
import axios from 'axios';
// import { Modal, Button } from 'react-bootstrap';
import { Modal } from 'bootstrap';


const JamKerja = ({ url, content }) => {

  const [namaShift, setNamaShift] = useState('')
  const [timein, setTimein] = useState('')
  const [timeout, setTimeout] = useState('')
  const [terlambat, setTerlambat] = useState('')
  const [plgcepat, setPlgcepat] = useState('')
  const [lembur, setLembur] = useState('')
  const [scope, setScope] = useState('')
  const [data, setData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [selectedData, setSelectedData] = useState(null)
  const [editFormData, setEditFormData] = useState({
    id: '',
    shift_name: '',
    time_in: '',
    time_out: '',
    max_terlambat: '',
    min_plg_cepat: '',
    min_lembur: '',
    scope: '',
  });




  const fetchData = async () => {
    setIsLoading(true)
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(url + "setting-absensi", {
        headers: { Authorization: `Bearer ${token}` },
      });
      setData(response.data.data);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false)
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(url + "add-setting-absen", {
        shift_name: namaShift,
        time_in: timein,
        time_out: timeout,
        max_terlambat: terlambat,
        min_plg_cepat: plgcepat,
        min_lembur: lembur,
        scope: scope,

      }, {
        headers: { Authorization: `Bearer ${token}` },
      });

      fetchData();

      swal({
        title: "Success!",
        text: "Setting Jam Kerja has been added successfully.",
        icon: "success",
        button: "OK",
      }).then(() => {
        // Close the modal
        const modalElement = document.getElementById('addSetting');
        if (modalElement) {
          const modalInstance = window.bootstrap.Modal.getOrCreateInstance(modalElement);
          setNamaShift('');
          setTimein('');
          setTimeout('');
          setTerlambat('');
          setPlgcepat('');
          setLembur('');
          setScope('');
          modalInstance.hide();
        }
      });

    } catch (error) {
      swal({
        title: "Oops!",
        text: error.response.data.message,
        icon: "error",
        button: "OK",
      }).then(() => {
        // Close the modal
        const modalElement = document.getElementById('addSetting');
        if (modalElement) {
          const modalInstance = window.bootstrap.Modal.getOrCreateInstance(modalElement);
          setNamaShift('');
          setTimein('');
          setTimeout('');
          setTerlambat('');
          setPlgcepat('');
          setLembur('');
          setScope('');
          modalInstance.hide();
        }
      });
    }
  };


  const handleEditClick = (item) => {
    setSelectedData(item);
  };


  // Use useEffect to show the modal after selectedSiswa is updated
  useEffect(() => {
    if (selectedData) {
      setEditFormData({
        id: selectedData.id,
        shift_name: selectedData.shift_name,
        time_in: selectedData.time_in,
        time_out: selectedData.time_out,
        max_terlambat: selectedData.max_terlambat,
        min_plg_cepat: selectedData.min_plg_cepat,
        min_lembur: selectedData.min_lembur,
        scope: selectedData.scope,

      });

      const modalElement = document.getElementById(`editJamKerja-${selectedData.id}`);
      if (modalElement) {
        const modalInstance = Modal.getOrCreateInstance(modalElement);
        modalInstance.show();
      }

    }
  }, [selectedData]);

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setEditFormData((prevData) => ({
      ...prevData,
      [id]: value
    }));
  };


  // Simpan Edit
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    // Logic to save the changes
    try {
      const token = localStorage.getItem("token");

      const response = await axios.post(url + "upd-absensi", {
        id: editFormData.id,
        shift_name: editFormData.shift_name,
        time_in: editFormData.time_in,
        time_out: editFormData.time_out,
        max_terlambat: editFormData.max_terlambat,
        min_plg_cepat: editFormData.min_plg_cepat,
        min_lembur: editFormData.min_lembur,
        scope: editFormData.scope,
      }, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response.data.status === true) {
        swal({
          title: "Success!",
          text: "Jam Kerja has been updated successfully.",
          icon: "success",
          button: "OK",
        }).then(() => {
          // Close the modal after the alert is acknowledged
          const modalElement = document.getElementById(`editJamKerja-${selectedData.id}`);
          if (modalElement) {
            const modalInstance = Modal.getOrCreateInstance(modalElement);
            modalInstance.hide();
          }
        });
      }
      window.location.reload();

    } catch (error) {
      console.log(error)
      swal({
        title: "Oops!",
        text: error.response.data.message,
        icon: "error",
        button: "OK",
      }).then(() => {
        // Close the modal after the alert is acknowledged
        const modalElement = document.getElementById(`editJamKerja-${selectedData.id}`);
        if (modalElement) {
          const modalInstance = Modal.getOrCreateInstance(modalElement);
          modalInstance.hide();
        }
      });
    }


    console.log('Saving changes:', editFormData);
    // Close the modal after saving
    const modalElement = document.getElementById(`editJamKerja-${selectedData.id}`);
    if (modalElement) {
      const modalInstance = Modal.getOrCreateInstance(modalElement);
      modalInstance.hide();
    }
  };



  useEffect(() => {
    fetchData();

  }, []);



  return (
    <div>
      <div className="container-fluid">
        <div className="page-title">
          <div className="row">
            <div className="col-sm-6 col-12">
              <h2>{content}</h2>
            </div>
            <div className="col-sm-6 col-12">
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><a href="index.html"><i className="fas fa-home"></i></a></li>
                <li className="breadcrumb-item">Dashboard /</li>

                <li className="breadcrumb-item active">{content}</li>
              </ol>
            </div>
          </div>
        </div>
      </div>


      <div className="container-fluid table-space basic_table">
        <div className="row">

          <div className="col-sm-12">
            <div className="card overflow-hidden">
              <div className="card-header">
                <div className="col-md-6 mt-3 mb-3">
                  <button className="btn btn-primary btn-md" type="button" data-bs-toggle="modal" data-bs-target="#addSetting">Tambah Data</button>

                </div>
              </div>
              <div className="card-body">
                <div className="table-responsive">

                  <table className="table">
                    <thead>
                      <tr className="border-bottom-secondary border-top-0">
                        <th scope="col">#</th>
                        <th scope="col" style={{ textAlign: 'left' }}>Shift Name</th>
                        <th scope="col" style={{ textAlign: 'left' }}>Jam Masuk</th>
                        <th scope="col" style={{ textAlign: 'left' }}>Jam Pulang</th>
                        <th scope="col" style={{ textAlign: 'left' }}>Max Terlambat</th>
                        <th scope="col" style={{ textAlign: 'left' }}>Min Pulang Cepat</th>
                        <th scope="col" style={{ textAlign: 'left' }}>Min Lembur</th>
                        <th scope="col" style={{ textAlign: 'left' }}>Scope</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {isLoading ? (
                        <tr>
                          <td colSpan="9" style={{ textAlign: 'center' }}>
                            <div className="spinner-border" role="status">
                              <span className="visually-hidden">Loading...</span>
                            </div>
                          </td>
                        </tr>
                      ) : data.length === 0 ? (
                        <tr>
                          <td colSpan="9" style={{ textAlign: 'center' }}>No data available</td>
                        </tr>
                      ) : (
                        data.map((item, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td style={{ textAlign: 'left' }}>{item.shift_name}</td>
                            <td style={{ textAlign: 'left' }}>{item.time_in}</td>
                            <td style={{ textAlign: 'left' }}>{item.time_out}</td>
                            <td style={{ textAlign: 'left' }}>{item.max_terlambat}</td>
                            <td style={{ textAlign: 'left' }}>{item.min_plg_cepat}</td>
                            <td style={{ textAlign: 'left' }}>{item.min_lembur}</td>
                            <td style={{ textAlign: 'left' }}>{item.scope}</td>
                            <td>
                              <button
                                className='btn btn-primary btn-sm'
                                onClick={() => handleEditClick(item)} // Use the function to show the modal
                              >
                                Edit
                              </button>
                            </td>

                            {/* Modal Edit */}
                            {selectedData && (
                              <div
                                className="modal fade"
                                id={`editJamKerja-${item.id}`}
                                tabIndex="-1"
                                role="dialog"
                                aria-labelledby="editJamKerja"
                                aria-hidden="true"
                                key={selectedData.id}
                              >
                                <div className="modal-dialog" role="document">
                                  <div className="modal-content">
                                    <div className="modal-header">
                                      <h5 className="modal-title">Edit Jam Kerja</h5>
                                      <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div className="modal-body">
                                      <form onSubmit={handleFormSubmit}>
                                        <div className="mb-3">
                                          <label htmlFor="nis" className="form-label">Shift Name</label>
                                          <input type="text" className="form-control" id="shift_name" value={editFormData.shift_name} onChange={handleInputChange} />
                                        </div>
                                        <div className="mb-3">
                                          <label htmlFor="nisn" className="form-label">Jam Masuk </label>
                                          <input type="time" className="form-control" id="time_in" value={editFormData.time_in} onChange={handleInputChange} />
                                        </div>
                                        <div className="mb-3">
                                          <label htmlFor="nama" className="form-label">Jam Pulang</label>
                                          <input type="time" className="form-control" id="time_out" value={editFormData.time_out} onChange={handleInputChange} />
                                        </div>
                                        <div className="mb-3">
                                          <label htmlFor="nama" className="form-label">Max Terlambat</label>
                                          <input type="number" className="form-control" id="max_terlambat" value={editFormData.max_terlambat} onChange={handleInputChange} />
                                        </div>
                                        <div className="mb-3">
                                          <label htmlFor="nama" className="form-label">Min Pulang Cepat</label>
                                          <input type="number" className="form-control" id="min_plg_cepat" value={editFormData.min_plg_cepat} onChange={handleInputChange} />
                                        </div>
                                        <div className="mb-3">
                                          <label htmlFor="nama" className="form-label">Min Lembur</label>
                                          <input type="number" className="form-control" id="min_lembur" value={editFormData.min_lembur} onChange={handleInputChange} />
                                        </div>
                                        <div className="mb-3">
                                          <label htmlFor="nama" className="form-label">Scope</label>

                                          <select className='form-control' id="scope" value={editFormData.scope} onChange={handleInputChange}>
                                            <option value="">Pilih Scope</option>
                                            <option value="1">Guru/Karyawan</option>
                                            <option value="2">Siswa</option>
                                          </select>
                                        </div>

                                        <button type="submit" className="btn btn-primary">Save changes</button>
                                      </form>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}

                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>

                </div>
              </div>

            </div>
          </div>
        </div>



        {/* Add Setting  */}
        <div className="modal fade" id="addSetting" tabindex="-1" role="dialog" aria-labelledby="addTp" aria-hidden="true">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-toggle-wrapper social-profile text-start dark-sign-up">
                <h3 className="modal-header justify-content-center border-0">Setting Absensi</h3>
                <div className="modal-body">

                  <form className="row" onSubmit={handleSubmit}>
                    <div className="col-md-12">
                      <label className="form-label" htmlFor="shiftname">Shift Name</label>
                      <input
                        className="form-control"
                        id="shiftname"
                        type="text"
                        placeholder="Masukkan Nama Shift"
                        value={namaShift}
                        onChange={(e) => setNamaShift(e.target.value)}
                        required />
                    </div>
                    <div className="col-md-12">
                      <label className="form-label" htmlFor="timein">Jam Masuk</label>
                      <input
                        className="form-control"
                        id="timein"
                        type="time"
                        placeholder="Masukkan Jam Masuk"
                        value={timein}
                        onChange={(e) => setTimein(e.target.value)}
                        required />
                    </div>
                    <div className="col-md-12">
                      <label className="form-label" htmlFor="timeout">Jam Pulang</label>
                      <input
                        className="form-control"
                        id="timeout"
                        type="time"
                        placeholder="Masukkan Jam Pulang"
                        value={timeout}
                        onChange={(e) => setTimeout(e.target.value)}
                        required />
                    </div>
                    <div className="col-md-12">
                      <label className="form-label" htmlFor="terlambat">Max Terlambat</label>
                      <input
                        className="form-control"
                        id="terlambat"
                        type="number"
                        placeholder="Masukkan Max Terlambat (dalam menit)"
                        value={terlambat}
                        onChange={(e) => setTerlambat(e.target.value)}
                        required />
                    </div>
                    <div className="col-md-12">
                      <label className="form-label" htmlFor="pulcep">Min Pulang Cepat</label>
                      <input
                        className="form-control"
                        id="pulcep"
                        type="number"
                        placeholder="Masukkan Max Pulang Cepat (dalam menit)"
                        value={plgcepat}
                        onChange={(e) => setPlgcepat(e.target.value)}
                        required />
                    </div>
                    <div className="col-md-12">
                      <label className="form-label" htmlFor="lembur">Min Lembur</label>
                      <input
                        className="form-control"
                        id="lembur"
                        type="number"
                        placeholder="Masukkan Min Lembur (dalam menit)"
                        value={lembur}
                        onChange={(e) => setLembur(e.target.value)}
                        required />
                    </div>
                    <div className="col-md-12">
                      <label className="form-label" htmlFor="lembur">Scope</label>
                      <select className='form-control' value={scope} onChange={(e) => setScope(e.target.value)}>
                        <option value="">Pilih Scope</option>
                        <option value="1">Guru/Karyawan</option>
                        <option value="2">Siswa</option>
                      </select>
                    </div>

                    <div className="col-md-12 mt-3">

                      <button className="btn btn-primary" type="submit">Simpan</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>




      </div>
    </div>
  )
}

export default JamKerja
